import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { VariablesGlobales } from '../../../global/variables';


@Injectable({
  providedIn: 'root'
})

export class CarritoComprasService {


  constructor(private http: HttpClient,
              private variablesGlobales: VariablesGlobales) {
  }

  readonly URL_API = this.variablesGlobales.URLweb + '/carrito-compras/';



    // --------------------Carrito de Compras


    carritoCompras_SeleccionarItems(sitioWeb: string, usuario: string){
        const fd = new FormData;
        fd.append('sitioWeb', sitioWeb);
        fd.append('email', usuario);
        return this.http.post<any>(this.URL_API + 'SeleccionarItemsCarritoCompras', fd);
      }

    carritoCompras_SeleccionarPedidosHechos(sitioWeb: string, usuario: string){
      const fd = new FormData;
      fd.append('sitioWeb', sitioWeb);
      fd.append('email', usuario);
      return this.http.post<any>(this.URL_API + 'SeleccionarPedidosHechos', fd);
    }

    carritoCompras_SeleccionarPedidosEntregados(sitioWeb: string, usuario: string){
      const fd = new FormData;
      fd.append('sitioWeb', sitioWeb);
      fd.append('email', usuario);
      return this.http.post<any>(this.URL_API + 'SeleccionarPedidosEntregados', fd);
    }

    carritoCompras_agregarItem(sitioWeb: string, usuario: string, idArticulo:string){
        const fd = new FormData;
        fd.append('sitioWeb', sitioWeb);
        fd.append('email', usuario);
        fd.append ('idArticulo', idArticulo);
        return this.http.post<any>(this.URL_API + 'AgregarItemCarritoCompras', fd);
      }

      carritoCompras_restarItem(sitioWeb: string, usuario: string, idArticulo:string){
        const fd = new FormData;
        fd.append('sitioWeb', sitioWeb);
        fd.append('email', usuario);
        fd.append ('idArticulo', idArticulo);
        return this.http.post<any>(this.URL_API + 'RestarItemCarritoCompras', fd);
      }

      carritoCompras_hacerPedido(sitioWeb: string, usuario: string){
        const fd = new FormData;
        fd.append('sitioWeb', sitioWeb);
        fd.append ('email', usuario);
        return this.http.post<any>(this.URL_API + 'HacerPedido', fd);
      }
      
      carritoCompras_cancelarPedido(sitioWeb: string, usuario: string){
        const fd = new FormData;
        fd.append('sitioWeb', sitioWeb);
        fd.append ('email', usuario);
        return this.http.post<any>(this.URL_API + 'cancelarPedido', fd);
      }

      hacerPedido_telefonoSelect(usuario: string){
        const fd = new FormData;
        fd.append ('email', usuario);
        return this.http.post<any>(this.URL_API + 'HacerPedido_telefonoSelect', fd);
      }

      hacerPedido_telefonoUpdate(usuario: string, telefono: string){
        const fd = new FormData;
        fd.append ('email', usuario);
        fd.append ('telefono', telefono);
        return this.http.post<any>(this.URL_API + 'HacerPedido_telefonoUpdate', fd);
      }
}