import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { VariablesGlobales } from '../../../global/variables';


@Injectable({
  providedIn: 'root'
})

export class SitioWebService {


  constructor(private http: HttpClient,
              private variablesGlobales: VariablesGlobales) {
  }

  readonly URL_API = this.variablesGlobales.URLweb + '/';


  // --------------------SitioWeb

  SitioWeb_Select(sitioWeb: string){
    const fd = new FormData;
    fd.append('sitioWeb', sitioWeb)
    return this.http.post<any>(this.URL_API + 'SitioWeb_Select', fd);
  }


  // --------------------Fotos

  SitioWeb_Select_Carousel(sitioWeb: string){
    const fd = new FormData;
    fd.append('sitioWeb', sitioWeb)
    return this.http.post<any>(this.URL_API + 'SitioWeb_Select_Carousel', fd);
  }

  Fotos_SelectArticulosFotos(sitioWeb: string) {
    const fd = new FormData;
    fd.append('sitioWeb', sitioWeb)
    return this.http.post<any>(this.URL_API + 'Fotos_SelectArticulosFotos',fd);
  }

  Fotos_SelectArticuloGaleria(sitioWeb: string, valor: string) {
    const fd = new FormData;
    fd.append('sitioWeb', sitioWeb)
    fd.append('valor', valor)
    return this.http.post<any>(this.URL_API + 'Fotos_SelectArticuloGaleria', fd);
  }

  Fotos_TipoProducto_filtros(sitioWeb: string) {
    const fd = new FormData;
    fd.append('sitioWeb', sitioWeb)
    return this.http.post<any>(this.URL_API + 'fotos_tipoProducto_filtros', fd);
  }

  Fotos_TipoProducto_select(sitioWeb: string) {
    const fd = new FormData;
    fd.append('sitioWeb', sitioWeb)
    return this.http.post<any>(this.URL_API + 'fotos_tipoProducto_select', fd);
  }

  fotos_tipoProducto_SelectArticulosFotos(sitioWeb: string, valor: string) {
    const fd = new FormData;
    fd.append('sitioWeb', sitioWeb)
    fd.append ('valor', valor);
    return this.http.post<any>(this.URL_API + 'fotos_tipoProducto_SelectArticulosFotos', fd);
  }

  fotos_search_SelectArticulosFotos(sitioWeb: string, valor: string) {
    const fd = new FormData;
    fd.append('sitioWeb', sitioWeb)
    fd.append ('valor', valor);
    return this.http.post<any>(this.URL_API + 'fotos_search_SelectArticulosFotos', fd);
  }



}

