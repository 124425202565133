import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-conocer-mas1',
  templateUrl: './conocer-mas1.component.html',
  styleUrls: ['./conocer-mas1.component.css']
})
export class ConocerMas1Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
    setTimeout(function () { 
      document.getElementById("wrapper").scrollIntoView({ behavior: "smooth", block: 'start', inline: 'center' });
    }, 100)
  }

}
