<body>

    <div class="is-preload">
        <!-- Wrapper -->
        <div id="wrapper">

            <!-- Header -->
                <!-- <header id="header" class="alt"> -->
        <div style="text-align: center; padding-top: 40px; padding-bottom:20px">
                <div class="animated rotateIn"><img routerLink="/" style="width: 5em; height: 5em; border-radius: 3em;" src="assets/css/images/VivaVenta.PNG" alt="" />
        </div>
        <!-- <h1 >VivaVenta</h1> -->
        <br>
        <br>
        <p>
            Sistema de Gestión Online Multiplataforma de Inventario, Compra y Venta, Gestión de Pedidos de Clientes, Administración de Clientes, 
            Proveedores, Ingresos, Gastos, y Chat personalizado.
        </p>    
        <p>
            Disponé de una Página Web para tu comercio online con actualización de Stock, tus datos de contacto, Carrito de compras, Organizador de Pedidos, y Chat con tus clientes.
        </p>    
        <p>
            Administración a través de la Computadora o del Celular, con stock actualizable automaticamente en la nube. Desde el Celular (solo con APP Android) podés leer códigos de barra de los productos, y tenés la capacidad de sacar fotos directamente de los productos o servicios.
        </p>
        <p>
            Gestión de Reportes por cada módulo, exportables a planillas de cálculo, con Informes de Stock, Caja y Ganancia.
        </p>    
        <p>
            Acceso de usuarios empleados o colaboradores con limitación de accesos y gestión de control de cuentas.
        </p>    
        <p>
            Cuenta con un Generador de Códigos de Barras, con los que podés rotular tus productos para luego identificarlos desde el Celular.
        </p> 
        <p>
            Para una fácil migración de productos y stock, cuenta con un importador de planillas de cálculos de los productos a cargar.
        </p>    
        <p>
            * En cada pantalla de la aplicación encontrará en el borde superior derecho un ícono de ayuda "(?)" donde se mostrará una ayuda de lo que puede realizar en dicha sección.
        </p>    
        
      </div>


            <!-- Footer -->
                <footer id="footer">
                    <section>
                        <h2>VivaVenta</h2>
                        <p>Ofrecemos soluciones en sistemas informáticos brindando así potenciar su comercio, exponenciando ventas y agilizando la administración.</p>
                        <ul class="actions">
                            <!-- <li><a href="generic.html" class="button">Learn More</a></li> -->
                        </ul>
                    </section>
                    <section>
                        <h2>Contacto</h2>
                        <dl class="alt">
                            <dt>WhatsApp</dt>
                            <dd>(054) 011 - 2288 - 20EB</dd>
                            <dt>Email</dt>
                            <dd><a href="#">contacto&#64;vivaventa.sytes.net</a></dd>
                        </dl>
                        <!-- <ul class="icons">
                            <li><a href="#" class="icon brands fa-twitter alt"><span class="label">Twitter</span></a></li>
                            <li><a href="#" class="icon brands fa-facebook-f alt"><span class="label">Facebook</span></a></li>
                            <li><a href="#" class="icon brands fa-instagram alt"><span class="label">Instagram</span></a></li>
                            <li><a href="#" class="icon brands fa-github alt"><span class="label">GitHub</span></a></li>
                            <li><a href="#" class="icon brands fa-dribbble alt"><span class="label">Dribbble</span></a></li>
                        </ul> -->
                    </section>
                    
                </footer>

        </div>

    </div>


<footer>
    <div>
        <h6></h6>
    </div>
    <div class="animated flip text-center">
        VivaVenta
    </div>
    <h6 routerLink="index/terminos" style="text-align: center;">
        Términos y Condiciones &nbsp;&nbsp;
    </h6>
</footer>

</body>