

<body>



    <div class="is-preload">
        <!-- Wrapper -->
        <div id="wrapper">

            <!-- Header -->
                <!-- <header id="header" class="alt"> -->                       
        <div style="text-align: center; padding-top: 70px; padding-bottom:200px">
                <div *ngIf="isMobile"> 
                    <div class="animated rotateIn"><img class="animated flip"  style="width: 40%; border-radius: 50%;" src="assets/css/images/VivaVentaCelular.PNG" alt="" />
                    </div>
                </div>
                <div *ngIf="!isMobile">
                    <div class="animated rotateIn"><img class="animated flip" style="width:15%; height: 5%; border-radius: 3em;" src="assets/css/images/VivaVenta.PNG" alt="" />
                    </div>
                </div>
        <!-- <h1 >VivaVenta</h1> -->

        tu comercio online
        <br>
        <br>
        <a (click)="login()"  class="button">Ingresar</a>
        <br>
        <br>
        <br>
        <p>Sistema de Gestión Comercial Online para Celular, PC y Tablets de Stock, Compra y Venta, Página Web, Gestión de Pedidos de Clientes, Administración de Clientes, 
          Proveedores, Ingresos, Gastos, Reportes y Chat personalizado.</p>

          <div class="">
              <br>
              <br>
              <!-- Hardcodeado -->
            <a style="background: rgb(115, 214, 164)" href="https://vivaventa.sytes.net/assets/app.apk" download class="button">Descargá la APP para Android</a>
            <!-- <a style="background: rgb(115, 214, 164)" href="https://play.google.com/store/apps/details?id=com.vivaventa.android.vivaventa" class="button">Descargá la APP para Android</a> -->            
          </div>
      </div>
                <!-- </header> -->


                <section>
                    
                    <div class="container mx-auto">
                        <h2 style="text-align: center;">Video de Introducción</h2>
                        <div class="row">
                                <div class="mx-auto">                                    
                                    <!-- <youtube-player videoId='hClr1sKJRMU' suggestedQuality="highres" height="300" width="300"></youtube-player> -->
                                </div>                         
                        </div>
                    </div>
                    <br>
                    <ul class="actions special">
                        <li><a href="assets/ManualVivaVenta.pdf" target="_blank" class="button">Descargar MANUAL DE USUARIO</a></li>
                    </ul>
                </section>
  
                <br>
                <br>
                <br>

              <!-- Main -->
              <div id="main">

                <!-- Nav -->
                <nav id="nav" style="position: sticky; top: 0px; margin: auto; z-index: 100; width: 100%;">
                    <ul>
                        <li><a href="#wrapper" > <i class="fas fa-home"></i> &nbsp; Inicio</a></li>
                        <li><a href="#intro" > <i class="fas fa-mobile-alt"></i> &nbsp; Celular y Pc</a></li>
                        <li><a href="#first" > <i class="fas fa-shopping-cart"></i> &nbsp; Ecommerce Web</a></li>
                        <li><a href="#second" > <i class="fas fa-car-side"></i> &nbsp; Pedidos de Clientes</a></li>
                        <li><a href="#cta" > <i class="fas fa-video"></i> &nbsp; Lector de Códigos de Barras</a></li>
                        <li><a href="#precio" > <i class="fas fa-money-bill-wave"></i> &nbsp; Precios</a></li>
                    </ul>
                </nav>



                    <!-- Introduction -->
                        <section id="intro" class="main">
                            <div class="spotlight">
                                <div class="content" >
                                    <header class="major">
                                        <h2>Para Celular, Pc y Tablets</h2>
                                    </header>
                                    <ul>
                                        <li>Stock - Inventario</li>
                                        <li>Compra y Venta</li>
                                        <li>Página Web de Ecommerce propia</li>
                                        <li>Gestión de Pedidos de Clientes</li>
                                        <li>Administración de Clientes, Proveedores, Ingresos, Gastos</li>
                                        <li>Reportes de todas las gestiones</li>
                                        <li>Chat personalizado con los Clientes</li>	
                                        <li>Acceso de usuarios empleados o colaboradores con limitación de accesos</li>	
                                        <li>Escaneá los "Códigos de Barras" directamente desde el celular (*)</li>	
                                        <li>Informes estadísticos</li>
                                        <li>Conexión a través de la nube</li>
                                        <li>Importador de productos de forma masiva para una fácil migración</li>
                                        <li>Generador de códigos de barras para rotular productos</li>
                                        <li>Actualizador masivo de costos y precios</li>
                                        <li>Ayuda en cada pantalla para una fácil comprensión de las funcionalidades</li>
                                        <li>Soporte técnico</li>
                                        <br>
                                        <br>	
                                        <!-- <ul class="actions special"> -->
                                            <!-- <li><a style="background: rgb(115, 214, 164)" href="assets/app.apk" download class="button">Descargá la APP para Android</a></li> -->
                                            <!-- <li><a style="background: rgb(115, 214, 164)" href="https://play.google.com/store/apps/details?id=com.vivaventa.android.vivaventa" class="button">Descargá la APP para Android</a></li> -->
                                        <!-- </ul> -->
                                        <br>
                                        <ul class="actions special">
                                            <li><a routerLink="index/conocer-mas1" class="button">Conocer más</a></li>
                                        </ul>
                                    </ul>
                                </div>
                                <span ><img style="width:80%; height: 80%; border: 1px solid black; border-radius: 5px;" src="assets/css/images/VivaventaAppCelularLeft.jpg" alt="" /></span>
                            </div>
                        </section>

                    <!-- First Section -->
                        <section id="first" class="main special">
                            <header class="major">
                                <h2>Disponga de una Página Web propia con todos tus productos sincronizados</h2>
                            </header>

                            <!-- <span class="animated flip"><img  style="width:100%; height: 100%; border-radius: 1em;" src="assets/css/images/VivaventaWeb.PNG" alt="" /></span> -->
                            <span>                            
                                <div  id="carousel-fade" class="carousel carousel-fade" data-ride="carousel">

                                    <div class="carousel-inner">
                                      <div class="carousel-item slide-image active">
                                        <img class="d-block w-100" src="assets/css/images/VivaventaWebPerfumeria.jpg">
                                      </div>
                                      <div class="carousel-item slide-image">
                                        <img class="d-block w-100" src="assets/css/images/VivaventaWebFerreteria.jpg">
                                      </div>
                                      <div class="carousel-item slide-image">
                                        <img class="d-block w-100" src="assets/css/images/VivaventaWebKiosco.jpg">
                                      </div>
                                      <div class="carousel-item slide-image">
                                        <img class="d-block w-100" src="assets/css/images/VivaventaWebContacto.jpg">
                                      </div>
                                      <div class="carousel-item slide-image">
                                        <img class="d-block w-100" src="assets/css/images/VivaventaWebECommerce.jpg">
                                      </div>
                                    </div>
                                    <a class="carousel-control-prev" href="#carousel-fade" role="button" data-slide="prev">
                                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span class="sr-only">Previous</span>
                                      </a>
                                      <a class="carousel-control-next" href="#carousel-fade" role="button" data-slide="next">
                                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span class="sr-only">Next</span>
                                      </a>
                                  </div>                            
                            </span>

                            <br>
                            <br>
                            <br>
                            
                            <ul class="features">
                                <li>
                                    <span class="icon solid major style1 fa-code"></span>
                                    <h3>Página Web</h3>
                                    <p>Mostrá todos tus productos a través de tu página web, date a conocer con tus datos de contacto</p>
                                </li>
                                <li>
                                    <span class="icon major style3 fa-copy"></span>
                                    <h3>Pedidos de Clientes</h3>
                                    <p>Gestioná los pedidos que te hagan tus clientes a través de tu página web, podés administrar los pedidos desde tu celular</p>
                                </li>
                                <li>
                                    <span class="icon major style5 fa-gem"></span>
                                    <h3>Chat personalizado</h3>
                                    <p>Chatea con tus clientes de manera personalizada a través de la página web directo al Celular</p>
                                </li>
                            </ul>
                            <footer class="major">
                                <ul class="actions special">
                                    <li><a routerLink="index/conocer-mas2" class="button">Cómo comenzar</a></li>
                                </ul>
                            </footer>
                        </section>

                    <!-- Second Section -->
                        <section id="second" class="main special">
                            <header class="major">
                                <h2>Aumentá tus ventas y simplificá la administración comercial</h2>
                                <p>Al tener tu gestión en la nube tenés sincronizado tu stock con los productos publicados en tu página web</p>
                            </header>
                            <ul class="statistics">
                                <li class="style1">
                                    <span class="icon solid fa-code-branch"></span>
                                    <strong>+</strong> Mayor <br> Exposición
                                </li>
                                <li class="style2">
                                    <span class="icon fa-folder-open"></span>
                                    <strong>-</strong> Agil <br>
                                    Administración
                                </li>
                                <li class="style3">
                                    <span class="icon solid fa-signal"></span>
                                    <strong>+</strong> Mayor <br> Eficiencia
                                </li>
                                <li class="style4">
                                    <span class="icon solid fa-laptop"></span>
                                    <strong>-</strong> Menos <br> Tiempo
                                </li>
                                <li class="style5">
                                    <span class="icon fa-gem"></span>
                                    <strong>+</strong> Más <br> Ventas										
                                </li>
                            </ul>
                            <p class="content">Contá con un sistema de gestión el cual podés utilizar desde el Celular, PC o Tablet, con una simple interfaz de usuario, podés llevar a cabo todas las gestiones comerciales de tu comercio, así como también disponer de una página web propia donde los clientes hagan sus pedidos, potenciando la disponibilidad de variedad de productos y simplificando la gestión de las órdenes de los pedidos.</p>
                            <footer class="major">
                                <ul class="actions special">
                                    <li><a routerLink="index/conocer-mas3" class="button">Preguntas Frecuentes</a></li>
                                </ul>
                            </footer>
                        </section>

                    <!-- Get Started -->
                    <section id="cta" class="main">
                        <div class="spotlight">
                            <div class="content" >
                                <header class="major">
                                    <h2>Lector de Códigos de Barras</h2>
                                </header>
                                <p>Sistema de gestión comercial online para Celular, PC y Tablets</p>
                                <p style="padding-right: 30px;"> <u> Desde el Celular (solo para APP Android) podés escanear Códigos de Barras de los productos</u> , simplificando la elección de los mismos en las ventas y compras, también podés sacar y cargar fotos de los productos directamente desde el Celular (solo para APP Android), las fotos cargadas se visualizarán en tu página web, para que los clientes puedan hacer pedidos personalizados. Cuenta también con un "Importador de Productos" para que puedas cargar de forma masiva todos los productos deseados. También cuenta con un "Generador de Códigos de Barras" para que puedas imprimir y luego rotular tus productos.</p>                  
                                <ul>                                    
                                    <br>
                                    <br>	
                                
                                    <br>
                                </ul>
                            </div>
                            <span ><img style="width:80%; height: 80%; border: 1px solid black; border-radius: 5px;" src="assets/css/images/VivaVentaScanCodeBar.jpg" alt="" /></span>
                        </div>

                        <div class="animated flip" style="margin: 0 auto; display:table;"><img  style="margin: 0 auto; display:table; width:80%; height: 100%; border-radius: 1em;" src="assets/css/images/VivaVentaScanCodeBar2.jpg" alt="" /></div>

                        <br>
                        <br>
                        <br>
                    </section>

                    


                        <section id="precio" class="main special">
                            <header class="major">
                                <h2>Precio del servicio</h2>
                                <p>El costo del servicio completo es de $x1 por 30 días o $x2 por año por usuario.</p>
                                  <ul class="actions special">
                                    <li><a (click)="botonPagar()" class="button" style="background-color: rgb(105, 183, 214);color:white;">Pagar</a></li>
                                </ul>
                                <p>Será contactado por mail por el primer pago para asociar su cuenta de MercadoPago a su cuenta de VivaVenta.</p>
                                <a (click)="login()">
                                    <h2 style="background-color: rgb(201, 226, 87);padding-top:10px; border-radius: 1em;">Creá tu cuenta Demo gratis por una duración de 45 días.</h2>
                                </a>
                                
                            </header>
                            <footer class="major">
                                <!-- <ul class="actions special">
                                    <li><a routerLink="index/conocer-mas4" class="button">Conocer más</a></li>
                                </ul> -->
                            </footer>
                        </section>
                </div>

            <!-- Footer -->
                <footer id="footer">
                    <section>
                        <h2>VivaVenta</h2>
                        <p>Ofrecemos soluciones en sistemas informáticos brindando así potenciar su comercio, exponenciando ventas y agilizando la administración.</p>
                        <ul class="actions">
                            <!-- <li><a href="generic.html" class="button">Learn More</a></li> -->
                        </ul>
                    </section>
                    <section>
                        <h2>Contacto</h2>
                        <dl class="alt">
                            <dt>WhatsApp</dt>
                            <dd>(054) 011 - 2288 - 20EB</dd>
                            <dt>Email</dt>
                            <dd><a href="#">contacto&#64;vivaventa.sytes.net</a></dd>
                        </dl>
                        <!-- <ul class="icons">
                            <li><a href="#" class="icon brands fa-twitter alt"><span class="label">Twitter</span></a></li>
                            <li><a href="#" class="icon brands fa-facebook-f alt"><span class="label">Facebook</span></a></li>
                            <li><a href="#" class="icon brands fa-instagram alt"><span class="label">Instagram</span></a></li>
                            <li><a href="#" class="icon brands fa-github alt"><span class="label">GitHub</span></a></li>
                            <li><a href="#" class="icon brands fa-dribbble alt"><span class="label">Dribbble</span></a></li>
                        </ul> -->
                    </section>
                    
                </footer>

        </div>

    </div>


<footer>
    <div>
        <h6></h6>
    </div>
    <div class="animated flip text-center">
        VivaVenta
    </div>
    <h6 style="text-align: center;">
        <a routerLink="index/terminos"> Términos y Condiciones </a>
    </h6>
    <br>
</footer>

</body>