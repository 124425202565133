
<div id="login"></div>

<div class="classbody">
  <div class="wrapper">

    <form [formGroup]="formulario" #articuloForm="ngForm" (ngSubmit)="signin(email.value, password.value)">      
        <div (click)="toWeb()">
          <img src="assets/loginIconUser.png" class="avatar" alt="Avatar Image" style="height: 150px; width: 150px; margin-left: -25px; margin-top: -25px;" >
           <h2 style="text-align: center; font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;"> Iniciar sesión </h2>
        </div>
        
        <div class="input-box">
            <input formControlName="email" #email type="text" placeholder="E-mail"
            required>              
            <i class="fas fa-user"></i>
              <div *ngIf="isFieldInvalid('email')">
                <small *ngIf="formulario.get('email')?.errors?.required" style="color: red; font-weight: bold;"> Este campo es requerido</small>
                <small *ngIf="formulario.get('email').errors?.email" style="color: red; font-weight: bold;">  Mail inválido </small>
                <small *ngIf="formulario.get('email').errors?.minlength" style="color: red; font-weight: bold;"> Mínimo 3 caracteres </small>
                <small *ngIf="formulario.get('email').errors?.maxlength" style="color: red; font-weight: bold;"> Máximo 50 caracteres </small>                
              </div>
        </div>
        <div class="input-box">
            <input formControlName="password" #password type="password" placeholder="Contraseña"
            required>
            <i class="fas fa-lock"></i>
              <div *ngIf="isFieldInvalid('password')">
                <small *ngIf="formulario.get('password')?.errors?.required" style="color: red; font-weight: bold;"> Este campo es requerido</small>
                <small *ngIf="formulario.get('password').errors?.pattern" style="color: red; font-weight: bold;"> Caracteres inválidos </small>
                <small *ngIf="formulario.get('password').errors?.minlength" style="color: red; font-weight: bold;"> Mínimo 8 caracteres </small>
                <small *ngIf="formulario.get('password').errors?.maxlength" style="color: red; font-weight: bold;"> Máximo 50 caracteres </small>
                              
              </div>
        </div>

        <div class="forgot" *ngIf="!isFieldInvalid('password'); else Template">
            <a (click)="RecuperarPassword()">Olvidé mi contraseña?</a>
        </div>

        <ng-template #Template>
          <h1> <br>  </h1>
        </ng-template>


        <button type="submit" class="btn" >Ingresar</button>

        <div class="register-link">
            <p> <a (click)="toRegistration()"> Crear cuenta </a>
            </p>
        </div>
    </form>

  </div>
</div>


<!-- OLD -->
<!-- OLD -->

<!-- 
<div class="main-header" id="home">

    <div class="login-box">  
      <img src="assets/css/images/VivaVenta.PNG" class="avatar" alt="Avatar Image" >
      <br>

      <h1>Acceda a su cuenta</h1>
      <br>

      <form [formGroup]="formulario" #articuloForm="ngForm" (ngSubmit)="signin(email.value, password.value)"
        class="form-box animated fadeInUp">

        <input formControlName="email" #email type="text" placeholder="Ingrese su E-mail">
        <div *ngIf="!formulario.controls['email'].valid && formulario.controls['email'].touched">
          <small *ngIf="formulario.get('email').errors?.required" style="color: red;"> Este campo es requerido</small>
          <small *ngIf="formulario.get('email').errors?.minlength" style="color: red;"> Este campo debe contener mínimo
            3 caracteres </small>
          <small *ngIf="formulario.get('email').errors?.maxlength" style="color: red;"> Este campo debe tener máximo 50
            caracteres </small>
          <small *ngIf="formulario.get('email').errors?.email" style="color: red;"> El Email es inválido </small>
        </div>
        <br>
        <input formControlName="password" #password type="password" placeholder="Ingrese la Contraseña">
        <div *ngIf="!formulario.controls['password'].valid && formulario.controls['password'].touched">
          <small *ngIf="formulario.get('password').errors?.required" style="color: red;"> Este campo es
            requerido</small>
          <small *ngIf="formulario.get('password').errors?.minlength" style="color: red;"> Este campo debe contener
            mínimo 8 caracteres </small>
          <small *ngIf="formulario.get('password').errors?.maxlength" style="color: red;"> Este campo debe tener máximo
            50 caracteres </small>
          <small *ngIf="formulario.get('password').errors?.pattern" style="color: red;"> Este campo tiene caracteres
            inválidos </small>
        </div>
        <br>
        <br>
        <button class="btn btn-primary btn-block" [disabled]="!formulario.valid" expand="full" type="submit"> INGRESAR </button>
      </form>


      <br>
        <div class="form-box animated fadeInUp" style="background-color: rgb(0, 0, 0); padding-left: 20px; padding-right: 20px; border-radius: 10px;" >
          <a (click)="toRegistration()"> &nbsp;&nbsp; <h5>Crear cuenta</h5> &nbsp;&nbsp; </a>
        </div>
      <br>

      <a >
        <h5 (click)="RecuperarPassword()" style="color:black;">
          * Olvidé mi contraseña?
        </h5>
      </a>

      <br>
      <br>
      <div class="text-center">
        <p class="btn btn-dark" style="color: white" (click)="toWeb()"> Volver </p>
      </div>

    </div>

  </div> -->


