import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { SitioWebService } from '../services/service';
import { VariablesGlobales } from '../../../global/variables';
import { CarritoComprasService } from '../services/carrito-compras.service';

import Swal from 'sweetalert2';


@Component({
  selector: 'app-galeria',
  templateUrl: './galeria.component.html',
  styleUrls: ['./galeria.component.css']
})
export class GaleriaComponent implements OnInit {

  @ViewChild("nombre") appDiv: ElementRef;

  constructor(private sitioWebService: SitioWebService,
              private variablesGlobales: VariablesGlobales,
              private carritoComprasService: CarritoComprasService,
              private router: Router,
              private activateRoute: ActivatedRoute) { }

  URLweb = this.variablesGlobales.URLweb;
  usuario;
  sitioWeb;
  fotos;
  idArticulo;
  tituloArticulo;
  precio;
  actualPage;
  sitioWebDatos;

  ngOnInit() {
    this.activateRoute.params.subscribe(params => {
      this.usuario = this.variablesGlobales.usuario;
      this.sitioWeb = params.sitioWeb;
      this.idArticulo = params.idArticulo;
      this.actualPage = params.actualPage;
      this.sitioWebService.Fotos_SelectArticuloGaleria(this.sitioWeb, this.idArticulo).subscribe(result => {
        this.fotos = result;
        this.tituloArticulo = result[0].tituloArticulo;
        this.precio = result[0].precio;
        document.getElementById("home").scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
      });
    })
    this.sitioWebService.SitioWeb_Select(this.sitioWeb).subscribe(result => {
        this.sitioWebDatos = result;
        this.appDiv.nativeElement.innerHTML = this.sitioWebDatos.nombre;
    })
  }

    AmpliarImagen(db: string, fileNameUUID: string, fileStore: string){
      Swal.fire({
        imageUrl: this.URLweb + '/' + fileStore + '/' + db + '/' + fileNameUUID,
        imageWidth: 1500,
        imageHeight: 500,
        heightAuto: false
      })
    }

  volver(){
    this.router.navigate(['/'+ this.sitioWeb, this.actualPage] )
  }

  agregarCarritoCompras(){
    if (this.usuario === '') {
      this.router.navigate(['/login/' + this.sitioWeb, this.actualPage]) 
    } else {
      Swal.fire({
        title: '¿Agregar al Carrito de Compras?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'No',
        confirmButtonText: 'Si',
        heightAuto: false
      }).then((result) => {
        if (result.value) {
        this.carritoComprasService.carritoCompras_agregarItem(this.sitioWeb, this.usuario, this.idArticulo).subscribe(result => {
          if (result[0].stock === 0) {
            Swal.fire({
              icon: 'error',
              title: 'Sin stock',
              text: 'No hay más productos disponibles de este item',
              showConfirmButton: true,
              heightAuto: false
            });
          } else {
            this.router.navigate(['/'+ this.sitioWeb, this.actualPage])
            Swal.fire({
              icon: 'success',
              title: 'Cargado!',
              text: 'Item agregado al Carrito de Compras',
              showConfirmButton: false,
              timer: 3000,
              heightAuto: false
            });
          }
        });
      }
    })
    }
  }

}
