import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm, FormControl, UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControl } from '@angular/forms';

import Swal from 'sweetalert2';

import { SignService } from '../../services/sign.services';
import { VariablesGlobales } from './../../../../global/variables';


@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss']
})
export class LoginPage implements OnInit {

  formulario: UntypedFormGroup;

  constructor(private signService: SignService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private variablesGlobales: VariablesGlobales,
              private fb: UntypedFormBuilder) {
                this.formulario = this.fb.group({
                  email: [null, [Validators.compose([Validators.required, Validators.email]),
                    Validators.minLength(3), Validators.maxLength(50)]],
                  password: [null, [Validators.compose([Validators.required, Validators.pattern(/^[ áéíóúäëïöüñÑ@a-zA-Z0-9_-]*$/),
                    Validators.minLength(8), Validators.maxLength(20)])]]
                });
               }

  sitioWeb;
  isFormSubmitted = false; 


  isFieldInvalid(fieldName: string): boolean {
    const field = this.formulario.get(fieldName);
    return field?.invalid && (field?.touched || field?.dirty || this.isFormSubmitted);
  }
  
  markAllAsTouched() {
    this.formulario.markAllAsTouched();
    this.isFormSubmitted = true;
  }

  ngOnInit() {
    // document.getElementById("home").scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    this.activatedRoute.params.subscribe(params => {
      this.sitioWeb = params.sitioWeb;
    })
    setTimeout(function () { 
      document.getElementById("login").scrollIntoView({ behavior: "smooth", block: 'start', inline: 'start' });
    }, 1000)

  }



  signin(email: string, password: string) {
      if (email === '' || password === '') {        
          this.markAllAsTouched();
          Swal.fire ({
            title:'Error',
            text:'Verificar los datos ingreados',
            icon:'error',
            timer: 2500,
            heightAuto: false
        });
        }
        else
        {
      this.signService.signin(email, password).subscribe(res => {
          if (res.message === 'USUARIO VALIDO') {
            this.variablesGlobales.usuario = email;
            // elimina el token si existe antes del setItem
            if (localStorage.getItem('token') === 'null') {
              localStorage.setItem ('token', res.token);
              this.variablesGlobales.usuario = email;
                  this.router.navigate(['/' + this.sitioWeb, 1])
              Swal.fire({
                icon: 'success',
                title: 'Ingreso correcto',
                showConfirmButton: false,
                timer: 1500,
                heightAuto: false
              });
              } else {
                localStorage.removeItem('token');
                localStorage.setItem ('token', res.token);
                  this.router.navigate(['/' + this.sitioWeb, 1])
              Swal.fire({
                icon: 'success',
                title: 'Ingreso correcto',
                showConfirmButton: false,
                timer: 1500,
                heightAuto: false
              });
            }
          }
          else if (res.message === 'USUARIO INVALIDO') {
            Swal.fire ({
              title:'Error',
              text:'Usuario incorrecto',
              icon:'error',
              heightAuto: false
          });
          }
          else if (res.message === 'CONTRASEÑA INVALIDA') {
            Swal.fire ({
              title:'Error',
              text:'Contraseña incorrecta',
              icon:'error',
              heightAuto: false
          });
          }
      });
    }
  }

  toRegistration(){
    this.router.navigate(['/registration/' + this.sitioWeb, 1])
  }

  toWeb(){
    this.router.navigate(['/' + this.sitioWeb, 1])
  }

 async RecuperarPassword(){

    const { value: email } = await Swal.fire({
      title: "Recuperar Contraseña",
      input: "email",
      inputPlaceholder: "Ingrese su Email"
    })

    if (email) {
      this.signService.recuperarPassword(email.toString()).subscribe(res => {
        Swal.fire({title:`Se envió su contraseña a su Email: ${email}`, heightAuto: false})
      })
      
    }

  }


}
