import { Component, OnInit, HostListener} from '@angular/core';
import { VariablesGlobales } from './../../global/variables';


declare const window: any;

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class IndexComponent implements OnInit {

  isMobile: boolean;
  safeURL;
  

  constructor(private variablesGlobales: VariablesGlobales,)
               {
                this.isMobile = window.innerWidth < 768;
               }

  @HostListener("window:scroll", [])
  onWindowScroll() {
    
    //ajusta en nav el tamaño de la fuente según el scroll
    const number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (number > 1400){
      (document.querySelector('nav') as HTMLElement).style.fontSize = '12px';
    } else {
      (document.querySelector('nav') as HTMLElement).style.fontSize = '16px';
      
    }

  

  }


  ionViewWillEnter(){
    this.isMobile = window.innerWidth < 768;
  }


  ngOnInit() {
    const tag = document.createElement('script');

    // tag.src = "https://www.youtube.com/iframe_api";
    document.body.appendChild(tag);
  }


  login() {
    window.location.href  = this.variablesGlobales.URLwebLogin;
    
  }

  botonPagar() {
    window.location.href  = this.variablesGlobales.URLwebRoot + '/assets/pagar.html';
  }


}


