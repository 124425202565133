import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { SitioWebService } from './services/service';
import { SignService } from './../sign/services/sign.services';
import { CarritoComprasService } from './services/carrito-compras.service';
import { ChatService } from './services/chat.service'
import { VariablesGlobales } from '../../global/variables';

import Swal from 'sweetalert2';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';



@Component({
  selector: 'app-sitio-web',
  templateUrl: './sitio-web.component.html',
  styleUrls: ['./sitio-web.component.css']
})
//  templateUrl: './sitio-web.component.html',
export class SitioWebComponent implements OnInit {

  @ViewChild("nombre") appDiv: ElementRef;
  @ViewChild("nombre2") appDiv2: ElementRef;
  isMobile: boolean;
  
  constructor(private sitioWebService: SitioWebService,
    private signService: SignService,
    private carritoComprasService: CarritoComprasService,
    private chatService: ChatService,
    public variablesGlobales: VariablesGlobales,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private ngbCarouselConfig: NgbCarouselConfig,
    private renderer: Renderer2) {
    ngbCarouselConfig.interval = 5000,
    ngbCarouselConfig.showNavigationArrows = false
    this.isMobile = window.innerWidth <= 940;
  }

  URLweb = this.variablesGlobales.URLweb;
  sitioWeb;
  actualPage; //: number = 1; // para el paginator
  actualPagePedidosGestionados;
  fotos;
  fotosTipoProducto;

  carousel;
  sitioWebDatos;
  // nombre;  @ViewChild("nombre", { static: false }) appDiv: ElementRef;
  titulo;
  observacion;
  aclaracion;
  direccion;
  telefonos;
  email;
  whatsup;
  foto;
  fileStore;
  DB;
  usuario: string='';

  CantidadMensajesSinLeer = 0;
  mostrarProductos = 'no';
  mostrarFiltros: boolean = false;
  mostrarCarritoCompras = 'no';
  carritoCompras = [];
  carritoComprasPedidos = [];
  carritoComprasEntregados = [];
  total;
  totalCantidad = 0;
  totalPedidos: number = 0;
  mostrarPedidos = false;
  mostrarRecibidos = false;


  ngOnInit() {

    console.log('toooooken')
    console.log(localStorage.getItem('token'))

    if (this.variablesGlobales.usuario !== '') {
      this.usuario = this.variablesGlobales.usuario;
     }   
     else {
           if (localStorage.getItem('token') !== null) {


// MEJORAR VALIDACION DE USUARIO
            
                    //mensajes
                    if (this.variablesGlobales.usuario !== ''){
                      this.chatService.buscarEmailporSitio(this.sitioWeb).subscribe(result => {
                          this.chatService.CantidadMensajesSinLeer(result[0].email, this.variablesGlobales.usuario).subscribe(result => {
                            this.CantidadMensajesSinLeer = result[0].cantidadSinLeer
                          })
                          })
                    }
                    //carga Carrito de Compras    
                    if (this.variablesGlobales.usuario !== '') {
                      this.mostrarCarritoCompras = 'si';
                      this.carritoComprasService.carritoCompras_SeleccionarItems(this.sitioWeb, this.usuario).subscribe(result => {
                                this.carritoCompras = result;
                                this.calculaTotal();
                                this.calculaTotalCantidad();
                              });
                      this.carritoComprasService.carritoCompras_SeleccionarPedidosHechos(this.sitioWeb, this.usuario).subscribe(result => {
                          this.carritoComprasPedidos = result;
                          //totalPedidos
                          this.totalPedidos = 0
                          for (var i = 0; i < this.carritoComprasPedidos.length; i++) {
                              this.totalPedidos = this.totalPedidos + (this.carritoComprasPedidos[i].precio * this.carritoComprasPedidos[i].cantidad)
                              }
                          })
                       this.carritoComprasService.carritoCompras_SeleccionarPedidosEntregados(this.sitioWeb, this.usuario).subscribe(result => {
                              this.carritoComprasEntregados = result;
                          })
                    }
       }
     }

    this.activatedRoute.params.subscribe(params => {
      this.sitioWeb = params.nombreSitioWeb;
      this.actualPage = params.actualPage;
              if (this.actualPage >= 0) { // para que si viene de la galeria vaya a productos y si no que cargue actualPage para que este cargada la variable para ejecutar la función agregarCarritoCompras para que el router pueda ir a login
                if (this.isMobile) {
                        setTimeout(function () { 
                          document.getElementById("productos").scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" }); 
                        },1000)
                      } else {
                        this.mostrarProductos = 'si';
                        setTimeout(function () { 
                          if (this.mostrarProductos !== 'no') {
                            document.getElementById("productos").scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" }); 
                            document.getElementById("productos").scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" }); 
                          }
                        }, 1000)
                      }
                    } 
                else 
                    {
                        this.actualPage = 0
                        setTimeout(function () { 
                          if (this.mostrarProductos !== 'no') {
                            document.getElementById("home").scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" }); 
                          }
                        }, 1000)
                    }
                this.sitioWebService.SitioWeb_Select(this.sitioWeb).subscribe(result => {
                    if (result.resultado === 'SitioInexistente') {
                          window.location.href = this.variablesGlobales.URLwebRoot
                        } 
                    else 
                        {
                          this.sitioWebDatos = result;

                          //this.nombre = this.sitioWebDatos.nombre;
                          console.log(this.sitioWebDatos.nombre)
                          this.appDiv.nativeElement.innerHTML = this.sitioWebDatos.nombre;                          
                          this.appDiv2.nativeElement.innerHTML = this.sitioWebDatos.nombre;                          
                          this.titulo = this.sitioWebDatos.titulo;
                          this.observacion = this.sitioWebDatos.observacion;
                          this.aclaracion = this.sitioWebDatos.aclaracion;
                          this.direccion = this.sitioWebDatos.direccion;
                          this.telefonos = this.sitioWebDatos.telefonos;
                          this.email = this.sitioWebDatos.email;
                          this.whatsup = this.sitioWebDatos.whatsup;
                          this.foto = this.sitioWebDatos.foto;
                          this.fileStore = this.sitioWebDatos.fileStore;
                          this.DB = this.sitioWebDatos.DB;

                          this.sitioWebService.SitioWeb_Select_Carousel(this.sitioWeb).subscribe(result => {
                            this.carousel = result;
                          })

                          this.sitioWebService.Fotos_SelectArticulosFotos(this.sitioWeb).subscribe(result => {
                            this.fotos = result;
                          });
                          this.sitioWebService.Fotos_TipoProducto_filtros(this.sitioWeb).subscribe(result => {
                            this.fotosTipoProducto = result;
                          })

                        }
                      })


      })
  }


  // Scroll Smooth
  toHome() {
    setTimeout(function () { 
      document.getElementById("home").scrollIntoView({ behavior: "smooth", block: 'start', inline: 'center' });
    }, 1000)
  }
 
  toProductos() {
    setTimeout(function () { 
      document.getElementById("productos").scrollIntoView({ behavior: "smooth", block: 'start', inline: 'start' });
    }, 1000)
  }

  toProductos2() {
    setTimeout(function () { 
      document.getElementById("productos").scrollIntoView({ behavior: "smooth", block: 'start', inline: 'start' });
    }, 2000)
  }

  toFotos() {
    setTimeout(function () { 
      document.getElementById("fotos").scrollIntoView({ behavior: "smooth", block: 'start', inline: 'start' });
    }, 1000)
  }

  toContacto() {
    document.getElementById("contacto").scrollIntoView({ behavior: "smooth", block: 'start', inline: 'start' });
  }

  toCarritoCompras() {
    if (typeof this.actualPage === 'undefined') {
      this.actualPage = 1;
    }

    if (this.variablesGlobales.usuario === '') {
      this.router.navigate(['/login/' + this.sitioWeb, this.actualPage])
    } else {
      this.mostrarCarritoCompras = 'si'
      document.getElementById("carritoCompras").scrollIntoView({ behavior: "smooth" });
      setTimeout(function () { 
        document.getElementById("carritoCompras").scrollIntoView({ behavior: "smooth", block: 'start', inline: 'start' });
      }, 1000)
                //carga Carrito de Compras    
                          if (this.variablesGlobales.usuario !== '') {
                            this.mostrarCarritoCompras = 'si';
                            this.carritoComprasService.carritoCompras_SeleccionarItems(this.sitioWeb, this.usuario).subscribe(result => {
                                      this.carritoCompras = result;
                                      this.calculaTotal();
                                      this.calculaTotalCantidad();
                                    });
                            this.carritoComprasService.carritoCompras_SeleccionarPedidosHechos(this.sitioWeb, this.usuario).subscribe(result => {
                                this.carritoComprasPedidos = result;
                                //totalPedidos
                                this.totalPedidos = 0
                                for (var i = 0; i < this.carritoComprasPedidos.length; i++) {
                                    this.totalPedidos = this.totalPedidos + (this.carritoComprasPedidos[i].precio * this.carritoComprasPedidos[i].cantidad)
                                    }
                                })
                             this.carritoComprasService.carritoCompras_SeleccionarPedidosEntregados(this.sitioWeb, this.usuario).subscribe(result => {
                                    this.carritoComprasEntregados = result;
                                })
                          }
        }
  }

  toMensajes(mensaje: string) {
    if (typeof this.actualPage === 'undefined') {
      this.actualPage = 1;
    }

    if (this.variablesGlobales.usuario === '') {
      this.router.navigate(['/login/' + this.sitioWeb, this.actualPage])
    } else {
      this.mostrarCarritoCompras = 'si'
      if (mensaje === '') {
        this.router.navigate(['mensajes', this.sitioWeb, this.variablesGlobales.usuario, ''], { skipLocationChange: true })
      } else {
        this.router.navigate(['mensajes', this.sitioWeb, this.variablesGlobales.usuario, `Consulta sobre "${mensaje}": `], { skipLocationChange: true })
      }
    }
  }

  toLogin() {
    if (typeof this.actualPage === 'undefined') {
      this.actualPage = 1;
    }

    this.router.navigate(['/login/' + this.sitioWeb, this.actualPage])
  }

  toLogOut() {

    Swal.fire({
      title: '¿Cerrar Sesión?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'No',
      confirmButtonText: 'Si',
      heightAuto: false
    }).then((result) => {
      if (result.value) {
        Swal.fire({
          icon: 'success',
          title: 'Sesión cerrada',
          showConfirmButton: false,
          timer: 1500,
          heightAuto: false
        });        
        this.variablesGlobales.usuario = '';
        this.usuario = '';
        this.mostrarCarritoCompras = 'no'
        localStorage.removeItem('Token');
        this.router.navigate(['/'+ this.sitioWeb,1])
        //this.ngOnInit();
      }
    });
  }
  // Scroll Smooth





  siMostrarProductos() {
    this.mostrarProductos = 'si'
    this.toProductos()
  }

  noMostrarProductos() {
    this.mostrarProductos = 'no'
  }

  FiltroTipoProducto(valor: string) {
    this.sitioWebService.fotos_tipoProducto_SelectArticulosFotos(this.sitioWeb, valor).subscribe(result => {
      this.fotos = result;
    })
  }


  search(valor: string) {
    this.mostrarFiltros = false;
    this.sitioWebService.fotos_search_SelectArticulosFotos(this.sitioWeb, valor).subscribe(result => {
      this.fotos = result;
      console.log(valor)
      this.toProductos()
      })
  }

  abrirGaleria(idArticulo: string) {
    if (typeof this.actualPage === 'undefined') {
      this.actualPage = 1;
    }
    this.router.navigate(['Galeria', this.sitioWeb, idArticulo, this.actualPage], { skipLocationChange: true });
  }

  agregarCarritoCompras(idArticulo: string) {
    if (this.variablesGlobales.usuario === '') {
      this.router.navigate(['/login/' + this.sitioWeb, this.actualPage])
    }
    else {
      Swal.fire({
        title: '¿Agregar al Carrito de Compras?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'No',
        confirmButtonText: 'Si',
        heightAuto: false
      }).then((result) => {
        if (result.value) {
          this.carritoComprasService.carritoCompras_agregarItem(this.sitioWeb, this.usuario, idArticulo).subscribe(result => {
            if (result[0].stock === 0) {
              Swal.fire({
                icon: 'error',
                title: 'Sin stock',
                text: 'No hay más productos disponibles de este item',
                showConfirmButton: true,
                heightAuto: false
              });
            } else {
              Swal.fire({
                icon: 'success',
                title: 'Cargado!',
                text: 'Item agregado al Carrito de Compras',
                showConfirmButton: false,
                timer: 3000,
                heightAuto: false
              });
              this.carritoComprasService.carritoCompras_SeleccionarItems(this.sitioWeb, this.usuario).subscribe(result => {
                this.carritoCompras = result;
                this.calculaTotal();
                this.calculaTotalCantidad();
              })
            }
          })
        }
      })
    }
  }

  sumarItem(idArticulo: string) {
    this.carritoComprasService.carritoCompras_agregarItem(this.sitioWeb, this.usuario, idArticulo).subscribe(result => { 
      if (result[0].stock === 0) {
          Swal.fire({
            icon: 'error',
            title: 'Sin stock',
            text: 'No hay más productos disponibles de este item',
            showConfirmButton: true,
            heightAuto: false
          });
        } else {
          const b = this.carritoCompras.findIndex(result => result.idArticulo === idArticulo); // si el codigo existe, suma cantidad
          this.carritoCompras[b].cantidad++;
          this.calculaTotal();
          this.calculaTotalCantidad();
        }
      });

  }

  restarItem(idArticulo: string) {
    const b = this.carritoCompras.findIndex(result => result.idArticulo === idArticulo); // si el codigo existe, suma cantidad
    this.carritoCompras[b].cantidad--

    if (this.carritoCompras[b].cantidad === 0) {
      this.carritoCompras.splice(b, 1);
    }
    this.calculaTotal();
    this.calculaTotalCantidad();

    this.carritoComprasService.carritoCompras_restarItem(this.sitioWeb, this.usuario, idArticulo).subscribe(result => { result });
  }

  calculaTotal() {  // calcula total
    let total = 0
    for (var i = 0; i < this.carritoCompras.length; i++) {
      total = total + (this.carritoCompras[i].precio * this.carritoCompras[i].cantidad)
    }
    this.total = total;
  }

  calculaTotalCantidad() {  // calcula total cantidad
    let total = 0
    for (var i = 0; i < this.carritoCompras.length; i++) {
      total = total + (this.carritoCompras[i].cantidad)
    }
    this.totalCantidad = total;
  }

  hacerPedido() {
    Swal.fire({
      title: '¿Confirmar Pedido?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'No',
      confirmButtonText: 'Si',
      heightAuto: false
    }).then((result1) => {
      if (result1.value) {
        this.carritoComprasService.hacerPedido_telefonoSelect(this.usuario).subscribe(result2 => {
          if (result2[0].telefono === '') {
            Swal.fire({
              title: 'Ingrese su teléfono de contacto',
              input: 'text',
              inputPlaceholder: 'Ingrese su teléfono de contacto',
              showCancelButton: true,
              heightAuto: false
            }).then((result3) => {
              let patron = new RegExp('^[-0-9 ()]{8,}');
              if (patron.test(result3.value.toString())) {
                if (result3.value.toString().length < 20) {
                  //alert('valor correcto');   HACE EL PEDIDO
                  this.carritoComprasService.hacerPedido_telefonoUpdate(this.usuario, result3.value.toString()).subscribe(result => {
                    this.haceElPedido()
                  })
                } else {
                  Swal.fire({
                    icon: 'error',
                    title: 'Número incorrecto',
                    text: 'Realice el pedido nuevamente',
                    showConfirmButton: true,
                    heightAuto: false
                  });
            }
              }
              else {
                Swal.fire({
                  icon: 'error',
                  title: 'Número incorrecto',
                  text: 'Realice el pedido nuevamente',
                  showConfirmButton: true,
                  heightAuto: false
                });
        }
            })
          } else {
            Swal.fire({
              title: result2[0].telefono,
              text: "¿Este es tu teléfono de contacto?",
              icon: 'question',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Si',
              cancelButtonText: 'No',
              heightAuto: false
            }).then((result4) => {
              if (result4.value) {
                //alert('valor correcto, continuar') HACE EL PEDIDO
                this.haceElPedido()
              } else if (result4.dismiss === Swal.DismissReason.cancel) {
                Swal.fire({
                  title: 'Ingrese su teléfono de contacto',
                  input: 'text',
                  inputPlaceholder: 'Ingrese su teléfono de contacto',
                  showCancelButton: true,
                  heightAuto: false
                }).then((result5) => {
                  let patron = new RegExp('^[-0-9 ()]{8,}');
                  if (patron.test(result5.value.toString())) {
                    if (result5.value.toString().length < 20) {
                      //alert('valor correcto, continuar'); HACE EL PEDIDO
                      this.carritoComprasService.hacerPedido_telefonoUpdate(this.usuario, result5.value.toString()).subscribe(result => {
                        this.haceElPedido()
                      })
                    } else {
                      Swal.fire({
                        icon: 'error',
                        title: 'Número incorrecto',
                        text: 'Realice el pedido nuevamente',
                        showConfirmButton: true,
                        heightAuto: false
                      });
                    }
                  }
                  else {
                    Swal.fire({
                      icon: 'error',
                      title: 'Número incorrecto',
                      text: 'Realice el pedido nuevamente',
                      showConfirmButton: true,
                      heightAuto: false
                    });
                }
                })
              }
            })
          }
        })
      }
    })
  }

  haceElPedido() {
    this.carritoComprasService.carritoCompras_hacerPedido(this.sitioWeb, this.usuario).subscribe(result => {
      Swal.fire({
        icon: 'success',
        title: 'Pedido realizado!',
        showConfirmButton: false,
        timer: 2500,
        heightAuto: false
      });
      setTimeout(function () {
        Swal.fire({
          title: 'Vendedor',
          text: result[0].mensajePedido,
          heightAuto: false
        })
      }, 2500)
      this.carritoCompras = [];
      this.total = 0
      this.totalCantidad = 0      
      this.mostrarPedidos = true;
      this.carritoComprasService.carritoCompras_SeleccionarPedidosHechos(this.sitioWeb, this.usuario).subscribe(result => {
        this.carritoComprasPedidos = result;
        //totalPedidos
        for (var i = 0; i < this.carritoComprasPedidos.length; i++) {
          this.totalPedidos = 0
          this.totalPedidos = this.totalPedidos + (this.carritoComprasPedidos[i].precio * this.carritoComprasPedidos[i].cantidad)
        }
      })
    })
  }

  cancelarPedido(){
    Swal.fire({
      title: '¿Cancelar Pedido?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'No',
      confirmButtonText: 'Si',
      heightAuto: false
    }).then((result1) => {
      if (result1.value) {
        this.carritoComprasService.carritoCompras_cancelarPedido(this.sitioWeb, this.usuario).subscribe(result => {
          Swal.fire({
            icon: 'success',
            title: 'Pedido cancelado!',
            showConfirmButton: false,
            timer: 2500,
            heightAuto: false
          });
          this.carritoComprasService.carritoCompras_SeleccionarPedidosHechos(this.sitioWeb, this.usuario).subscribe(result => {
            this.carritoComprasPedidos = result;
            //totalPedidos
            for (var i = 0; i < this.carritoComprasPedidos.length; i++) {
              this.totalPedidos = this.totalPedidos + (this.carritoComprasPedidos[i].precio * this.carritoComprasPedidos[i].cantidad)
            }
          })
        })
      }
    })
  }


  verMostrarPedidos() {
    this.mostrarPedidos = !this.mostrarPedidos
  }

  verMostrarRecibidos() {
    this.mostrarRecibidos = !this.mostrarRecibidos
  }

  EnviarMensaje(nombre: string, email: string, mensaje:string) { 
    mensaje = 'ENVIADO DESDE LA PAGINA WEB, NOMBRE: ' + nombre + '. MENSAJE: ' + mensaje

    this.chatService.buscarEmailporSitio(this.sitioWeb).subscribe(result => {
      this.chatService.enviarMensaje(email, result[0].email, mensaje).subscribe(result => {
        Swal.fire({
          icon: 'success',
          title: 'Mensaje enviado',
          showConfirmButton: false,
          timer: 2500,
          heightAuto: false
        });
        return false;
      })
    })
  }

  btnMostrarFiltros(){
    this.mostrarFiltros =! this.mostrarFiltros
  }

  goToHomePage(){
    window.location.href = this.variablesGlobales.URLwebRoot
  }
}
