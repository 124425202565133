import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { Index2Component } from './index2/index2.component';
import { IndexComponent } from './index-full/index.component';
import { LoginPage } from './sign/components/login/login.page';
import { RegistrationPage } from './sign/components/registration/registration.page';
import { SitioWebComponent } from './sitio-web/sitio-web.component';
import { GaleriaComponent } from './sitio-web/galeria/galeria.component';
import { ChatComponent } from './sitio-web/chat/chat.component';
import { ConocerMas1Component } from './index-full/conocer-mas1/conocer-mas1.component';
import { ConocerMas2Component } from './index-full/conocer-mas2/conocer-mas2.component';
import { ConocerMas3Component } from './index-full/conocer-mas3/conocer-mas3.component';
import { TerminosComponent } from './index-full/terminos/terminos.component';

const routes: Routes = [
  { path: '', component: Index2Component },
  { path: 'index2', component: IndexComponent },
  { path: 'index/conocer-mas1', component: ConocerMas1Component },
  { path: 'index/conocer-mas2', component: ConocerMas2Component },
  { path: 'index/conocer-mas3', component: ConocerMas3Component },
  { path: 'index/terminos', component: TerminosComponent },
  { path: ':nombreSitioWeb', component: SitioWebComponent },
  { path: ':nombreSitioWeb/:actualPage', component: SitioWebComponent },
  { path: 'Galeria/:sitioWeb/:idArticulo/:actualPage', component: GaleriaComponent },
  { path: 'login/:sitioWeb/:actualPage', component: LoginPage },
  { path: 'registration/:sitioWeb/:actualPage', component: RegistrationPage },
  { path: 'mensajes/:sitioWeb/:usuario/:mensaje', component: ChatComponent },  
  // agregar probando la siguiente linea para que cuando el backend(nodejs) sea inaccesible se valla a la index.html y no a la sitioWeb "vacio"
  //   { path: ':nombreSitioWeb', component: IndexComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
