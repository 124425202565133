<div class="container">
    <div class="col-md-12 mt-4">
        <div class="card">
          
            <div *ngFor="let mensaje of mensajes">
                <div *ngIf="myEmail !== mensaje.origen" class="mensaje destino-mensaje col-md-9">
                    <b style="color:blue"> {{ mensaje.origen }} </b> <br>
                    <span style="color:blue"> {{ mensaje.mensaje}} </span>
                    <div class="text-right"><br>
                        <small style="color:blue">  {{ mensaje.fecha }} </small>
                    </div>
                </div>

                <div *ngIf="myEmail === mensaje.origen" class="mensaje mi-mensaje offset-md-3">
                    <b style="color:green"> {{ mensaje.origen }} </b> <br>
                    <span style="color:green"> {{ mensaje.mensaje}} </span>
                    <div class="text-right"><br>
                        <small style="color:green"> {{ mensaje.fecha }} </small>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <br>
    <br>



    <div class="input-group">
        <textarea #mensaje [(ngModel)]="nuevoMsj" class="form-control" autofocus></textarea>
        &nbsp; &nbsp;
        <div class="input-group-append">
            <button [disabled]="nuevoMsj ===''" class="msg-btn btn btn-success" (click)="enviarMensaje(mensaje.value)">
                Enviar  &nbsp; <i class="fas fa-paper-plane pl-2"></i> </button>
        </div>
    </div>
    <br>

    <div class="text-center">
        <button class="btn btn-warning" (click)="volver()"> Volver </button>
    </div>
    <br>

    <div id="marca"></div>
    
</div>