

<div style="background-color:#636363;"  (click)="volver()">
  <div class="col-md-2 shadow3" style="color: white; text-align: center; margin-left: auto; margin-right:auto; padding-top: 20px; padding-bottom: 20px;" #nombre></div>
</div>


<div class="contorno2" style="font-size: 4px;"> 
  <p>
    &nbsp;
  </p>
</div>


<br>
<br>
<div #home ></div>


    <div class="col-md-12">

      <div class="">
          <div class="card-body animated fadeIn"> 

            <div class="text-center" style="margin-top: -10px;">
                <h2 class="shadow3">{{ tituloArticulo }}  </h2>
                <h2 class="shadow3" style="color:rgb(63, 116, 216); margin-bottom: 5px;"> $ {{ precio }} </h2>

            </div>

            <div class="row animated fadeIn">
              <div class="col-md-3 p-2" *ngFor="let foto of fotos" style="display:block; margin:auto;">
                  <div class="card card-photo" >
  
                      <img [src]="URLweb + '/' + foto.fileStore + '/' + foto.db + '/' + foto.fileNameUUID"  class="img-card-top w-100" (click)="AmpliarImagen(foto.db,foto.fileNameUUID, foto.fileStore)" style="border-radius: 7px;">
                      <br>
                      <div class="text-center">
                        <h5> {{ foto.titulo }} </h5>
                          {{ foto.descripcion }}
                      </div>
                      <br>
                   </div>
                </div>
            </div>  
          </div>


<div class="text-center" style="margin-top: -20px;">
     <br>
     <button type="button" (click)="agregarCarritoCompras()" class="btn btn-warning animated zoomInDown shadow3"> Agregar al Carrito de compras </button>
     <br>     
     <br>
     <button type="button" (click)="volver()" class="btn btn-success animated zoomInDown shadow3" autofocus> Volver </button>
     <br>
     <br>
</div>
          







     
