import { getHtmlTagDefinition } from '@angular/compiler';
import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { filter } from 'rxjs/operators';

declare var gtag;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'vivaventa';
  
 // Para Google Analytics 
  constructor (
    private router: Router
  ) {
    const navEndEvents$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    );

    // navEndEvents$.subscribe((event: NavigationEnd) => {
    //   gtag('config','G-QJTQC27Y3S', {
    //     'page_path': event.urlAfterRedirects
    //   });
    // });
  
  }

}

