import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VariablesGlobales {


  constructor() { }

      // variables
      public usuario = '';
 
 // Desarrollo local
      // public URLweb = 'http://localhost:3000';
      // public URLwebLogin = 'http://localhost:8100'; // para ir al sitio de IonicVenta, está de prueba
      // public URLwebRoot = 'http://localhost:4200' //  para ir al sitio root si no encuentra un sitioWeb
 
// Desarrollo intranet
      // public URLweb = 'http://192.168.0.10:3000';
      // public URLwebLogin = 'http://192.168.0.10/app'; // para ir al sitio de IonicVenta, está de prueba
      // public URLwebRoot = 'http://192.168.0.10' //  para ir al sitio root si no encuentra un sitioWeb

// Homologación
      public URLweb = 'https://vivaventa.sytes.net:3000';
      public URLwebLogin = 'https://vivaventa.sytes.net/app'; // para ir al sitio de IonicVenta, está de prueba
      public URLwebRoot = 'https://vivaventa.sytes.net' //  para ir al sitio root si no encuentra un sitioWeb
// 
// Producción
      // public URLweb = 'https://www.vivaventa.ar:3000';
      // public URLwebLogin = 'https://www.vivaventa.ar/app/index.html'; // para ir al sitio de IonicVenta, está de prueba
      // public URLwebRoot = 'https://www.vivaventa.ar' //  para ir al sitio root si no encuentra un sitioWeb
    
}