
<div id="registracion"></div>

  <form [formGroup]="formulario"  #articuloForm="ngForm" (ngSubmit)="signup(nombre.value, apellido.value, email.value, password.value)" class="form-box animated fadeInUp">
    
    <br>
    <h2 class="shadow3" style="color: rgb(85, 83, 83)"> Registración</h2>
    <br>

    <input formControlName="nombre" #nombre type="text" placeholder="Ingrese su Nombre" autofocus>
        <div *ngIf="!formulario.controls['nombre'].valid && formulario.controls['nombre'].touched">
            <small *ngIf="formulario.get('nombre').errors?.required" style="color: red;"> Este campo es requerido</small>
            <small *ngIf="formulario.get('nombre').errors?.minlength" style="color: red;"> Este campo debe contener mínimo 3 caracteres </small>
            <small *ngIf="formulario.get('nombre').errors?.maxlength" style="color: red;"> Este campo debe tener máximo 50 caracteres </small>
            <small *ngIf="formulario.get('nombre').errors?.pattern" style="color: red;"> Este campo tiene caracteres inválidos </small>
         </div>

    <input formControlName="apellido" #apellido type="text" placeholder="Ingrese su Apellido">
    <div *ngIf="!formulario.controls['apellido'].valid && formulario.controls['apellido'].touched">
        <small *ngIf="formulario.get('apellido').errors?.required" style="color: red;"> Este campo es requerido</small>
        <small *ngIf="formulario.get('apellido').errors?.minlength" style="color: red;"> Este campo debe contener mínimo 3 caracteres </small>
        <small *ngIf="formulario.get('apellido').errors?.maxlength" style="color: red;"> Este campo debe tener máximo 50 caracteres </small>
        <small *ngIf="formulario.get('apellido').errors?.pattern" style="color: red;"> Este campo tiene caracteres inválidos </small>
     </div>

    <input formControlName="email" #email type="text" placeholder="Ingrese su E-mail">
        <div *ngIf="!formulario.controls['email'].valid && formulario.controls['email'].touched">
            <small *ngIf="formulario.get('email').errors?.required" style="color: red;"> Este campo es requerido</small>
            <small *ngIf="formulario.get('email').errors?.email" style="color: red;"> El Email es inválido </small>
            <small *ngIf="formulario.get('email').errors?.maxlength" style="color: red;"> Este campo debe tener máximo 50 caracteres </small>
        </div>

    <input formControlName="password" #password type="password" placeholder="Ingrese la Contraseña">
        <div *ngIf="!formulario.controls['password'].valid && formulario.controls['password'].touched">
            <small *ngIf="formulario.get('password').errors?.required" style="color: red;"> Este campo es requerido</small>
            <small *ngIf="formulario.get('password').errors?.minlength" style="color: red;"> Este campo debe contener mínimo 8 caracteres </small>
            <small *ngIf="formulario.get('password').errors?.maxlength" style="color: red;"> Este campo debe tener máximo 20 caracteres </small>
        </div>

    <input formControlName="password2" type="password" placeholder="repita la Contraseña">
        <small *ngIf="formulario.errors?.misMatch" style="color: red;"> Las contraseñas no coinciden</small>
    <div *ngIf="!formulario.controls['password2'].valid && formulario.controls['password2'].touched">
        <small *ngIf="formulario.get('password2').errors?.required" style="color: red;"> Este campo es requerido</small>
    </div>

    <!-- <br>
        <div style="text-align: center;">
            <re-captcha (resolved)="captchaResolved($event)" siteKey="6LeolBUaAAAAAMap6EtkkJCo4gkuzyqKuZT0Uke4" style='display: inline-block'></re-captcha>
        </div>
    <br> -->

    <button [disabled]="!formulario.valid" type="submit">
        <h6 style="margin-top: 7px;" class="shadow3">Crear Cuenta</h6>
    </button>
    
    <div *ngIf="!formulario.valid">
        <br>
        <p class="shadow3" style='color: red'> "Crear Cuenta" NO DISPONIBLE:</p>
        <P class="shadow3"style='color: purple'> Revisar los campos del formulario</P>
    </div>

    <br>
    <p class="btn btn-info shadow3" style="color: white" (click)="toLogin()"> Volver </p>
</form>


