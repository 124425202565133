
<body>

    <div class="is-preload">
        <!-- Wrapper -->
        <div id="wrapper">

            <!-- Header -->
                <!-- <header id="header" class="alt"> -->
        <div style="text-align: center; padding-top: 40px; padding-bottom:20px">
                <div class="animated rotateIn"><img (click)="goToInicio();" style="width: 5em; height: 5em; border-radius: 3em;" src="assets/css/images/VivaVenta.PNG" alt="" />
        </div>
        <br>
        <br>
        <h1>Términos y Condiciones</h1>
        <br>

 <h3 style="text-align: left"> Contrato </h3>
 <p style="text-align: left">
<br>
<br>

Este contrato describe los términos y condiciones generales (los “Términos y Condiciones Generales”) aplicables al uso de los servicios ofrecidos el mismo, (“los Servicios”) dentro del sitio www.vivaventa.ar (“VivaVenta” el “sitio” y la aplicación “VivaVenta”). Cualquier persona (en adelante “Usuario” o en plural “Usuarios”) que desee acceder y/o usar el sitio o los Servicios podrá hacerlo sujetándose a los Términos y Condiciones Generales respectivos, junto con todas las demás políticas y principios que rigen VivaVenta y que son incorporados al presente por referencia.
<br>
<br>
CUALQUIER PERSONA QUE NO ACEPTE ESTOS TÉRMINOS Y CONDICIONES GENERALES, LOS CUALES TIENEN UN CARÁCTER OBLIGATORIO Y VINCULANTE, DEBERÁ ABSTENERSE DE UTILIZAR EL SITIO Y/O LOS SERVICIOS.
<br>
<br>
El Usuario debe leer, entender y aceptar todas las condiciones establecidas en los Términos y Condiciones Generales y en las Políticas de Privacidad así como en los demás documentos incorporados a los mismos por referencia, previo a su registración como Usuario de VivaVenta, optando de forma expresa por recibir los mismos y toda otra información por medios digitales.
<br>
<br>
Capacidad
<br>
Los Servicios sólo están disponibles para personas que tengan capacidad legal para contratar. No podrán utilizar los servicios las personas que no tengan esa capacidad, los menores de edad o Usuarios de VivaVenta que hayan sido suspendidos temporalmente o inhabilitados definitivamente. Si estás registrando un Usuario como Empresa, debes tener capacidad para contratar a nombre de tal entidad y de obligar a la misma en los términos de este Acuerdo.
<br>
<br>
Registración
<br>
Es obligatorio completar el formulario de registración en todos sus campos con datos válidos para poder utilizar los servicios que brinda VivaVenta. El futuro Usuario deberá completarlo con su información personal de manera exacta, precisa y verdadera (“Datos Personales”) y asume el compromiso de actualizar los Datos Personales conforme resulte necesario. El Usuario presta expresa conformidad con que VivaVenta utilice diversos medios para identificar sus datos personales, asumiendo el Usuario la obligación de revisarlos y mantenerlos actualizados. VivaVenta NO se responsabiliza por la certeza de los Datos Personales de los Usuarios. Los Usuarios garantizan y responden, en cualquier caso, de la veracidad, exactitud, vigencia y autenticidad de sus Datos Personales.
El Usuario accederá a su cuenta personal (“Cuenta”) mediante el ingreso de su Usuario y clave de seguridad personal elegida (“Clave de Seguridad”). El Usuario se obliga a mantener la confidencialidad de su Clave de Seguridad.
La Cuenta es personal, única e intransferible, y está prohibido que un mismo Usuario registre o posea más de una Cuenta. En caso que VivaVenta detecte distintas Cuentas que contengan datos coincidentes o relacionados, podrá cancelar, suspender o inhabilitarlas.
VivaVenta se reserva el derecho de rechazar cualquier solicitud de registración o de cancelar una registración previamente aceptada, sin que esté obligado a comunicar o exponer las razones de su decisión y sin que ello genere algún derecho a indemnización o resarcimiento.
<br>
<br>
Modificaciones del Acuerdo
<br>
VivaVenta podrá modificar los Términos y Condiciones Generales en cualquier momento haciendo públicos en el Sitio los términos modificados. Todos los términos modificados entrarán en vigor a partir del día de su publicación. Todo usuario que no esté de acuerdo con las modificaciones efectuadas por VivaVenta deberá dejar de usar el Sitio y su aplicación.
El uso del sitio y/o sus servicios implica la aceptación de estos Términos y Condiciones generales de uso de VivaVenta.
<br>
<br>
Publicación de bienes y/o servicios
<br>
El Usuario deberá ofrecer a la venta los bienes y promover los servicios en las categorías apropiadas. Las publicaciones podrán incluir textos descriptivos, gráficos, fotografías y otros contenidos y condiciones pertinentes para la venta del bien o la promoción del servicio, siempre que no violen ninguna disposición de este acuerdo o demás políticas de VivaVenta. El bien o servicio ofrecido por el Usuario vendedor debe ser exactamente descripto en cuanto a sus condiciones y características relevantes. Se entiende y presume que mediante la inclusión del bien o servicio en VivaVenta, el Usuario acepta que tiene la intención y el derecho de vender el bien u ofrecer el servicio referido, o está facultado para ello por su titular. Asimismo, el Usuario acepta que tiene disponible el bien publicado para su entrega inmediata o el servicio para su prestación en el plazo acordado. Se establece que los precios de los bienes o servicios publicados deberán ser expresados con IVA incluido cuando corresponda la aplicación del mismo, y en moneda de curso legal. 
Inclusión de imágenes y fotografías: 
El usuario puede incluir imágenes y fotografías del bien o servicio ofrecido siempre que las mismas se correspondan con el mismo, salvo que se trate de bienes o productos o de servicios que por su naturaleza no permiten esa correspondencia. El usuario declara y garantiza que está facultado para incluir las imágenes y fotografías contenidas en sus publicaciones, siendo responsable por cualquier infracción a derechos de terceros.
VivaVenta podrá impedir la publicación de la fotografía, e incluso del bien o servicio, si interpretara, a su exclusivo criterio, que la imagen no cumple con los presentes Términos y Condiciones.
Violaciones del Sistema o Bases de Datos: 
No está permitida ninguna acción o uso de dispositivo, software, u otro medio tendiente a interferir tanto en las actividades y operatoria de VivaVenta. Cualquier intromisión, tentativa o actividad violatoria o contraria a las leyes sobre derecho de propiedad intelectual y/o a las prohibiciones estipuladas en este contrato harán pasible a su responsable de las acciones legales pertinentes, y a las sanciones previstas por este acuerdo, así como lo hará responsable de indemnizar los daños ocasionados.
<br>
<br>
Alcance de los servicios de VivaVenta
<br>
Este acuerdo no crea ningún contrato de sociedad, de mandato, de franquicia, o relación laboral entre VivaVenta y el usuario. El usuario reconoce y acepta que VivaVenta no es parte en ninguna operación, ni tiene control alguno sobre la calidad, seguridad o legalidad de los bienes y servicios anunciados, la veracidad o exactitud de los anuncios, la capacidad de los Usuarios para vender o comprar artículos. VivaVenta no puede asegurar que un usuario completará una operación ni podrá verificar la identidad o datos personales ingresados por los usuarios. VivaVenta no garantiza la veracidad de la publicidad de terceros que aparezca en el sitio y no será responsable por la correspondencia o contratos que el usuario celebre con dichos terceros o con otros usuarios.
El Cliente tendrá derecho a la utilización del Servicio, responsabilizándose del contenido de la información que ingrese, y se obliga a consultar en forma periódica las normas relativas al uso del Servicio que forman parte de los presentes términos y condiciones.
La plataforma destinada a la prestación del Servicio puede ser utilizada únicamente con fines lícitos. Asimismo, se hace íntegramente responsable de cumplir con todas sus obligaciones relacionadas con su actividad económica sean estas, sin limitar, de índole laborales, fiscales, impositivas, de la seguridad social, desligando a VivaVenta de cualquier responsabilidad que directa o indirectamente pueda imputársele.
Fallas en el sistema:
VivaVenta no se responsabiliza por cualquier daño, perjuicio o pérdida al usuario causados por fallas en el sistema, en el servidor o en Internet. VivaVenta tampoco será responsable por cualquier virus que pudiera infectar el equipo del usuario como consecuencia del acceso, uso o examen de su sitio web o a raíz de cualquier transferencia de datos, archivos, imágenes, textos, o audio contenidos en el mismo. Los usuarios NO podrán imputarle responsabilidad alguna ni exigir pago por lucro cesante, en virtud de perjuicios resultantes de dificultades técnicas o fallas en los sistemas o en Internet. VivaVenta no garantiza el acceso y uso continuado o ininterrumpido de su sitio. El sistema puede eventualmente no estar disponible debido a dificultades técnicas o fallas de Internet, o por cualquier otra circunstancia ajena a VivaVenta; en tales casos se procurará restablecerlo con la mayor celeridad posible sin que por ello pueda imputársele algún tipo de responsabilidad. VivaVenta no será responsable por ningún error u omisión contenidos en su sitio web.
<br>
<br>
Condiciones y formas de pago
<br>
El Usuario se obliga al pago, en tiempo y forma, del precio del servicio. El Usuario abonará el precio del Servicio por periodo adelantado. Los pagos podrán realizarse por MercadoPago. La mora en el pago del Servicio operará de manera automática en la falta de acceso al Servicio. Los precios podrán ser modificados, comunicándolo al Cliente con antelación de al menos treinta (30) días a su entrada en vigencia. El Cliente podrá rechazar las modificaciones de precios en la forma, el medio, y en un todo de acuerdo. La falta de pago en término del Servicio, facultará al Sitio, a suspender la provisión del Servicio de forma inmediata, hasta su efectivo pago. Una vez transcurridos el vencimiento del servicio, el Sitio podrá optar por dar rescindir la relación emergente de la aceptación de los presentes Términos y Condiciones, sin interpelación alguna.
Jurisdicción y Ley Aplicable
Este acuerdo estará regido en todos sus puntos por las leyes vigentes en la República Argentina.
Cualquier controversia derivada del presente acuerdo, su existencia, validez, interpretación, alcance o cumplimiento, será sometida ante la Justicia Nacional Ordinaria.
<br>
<br>
Artículos Prohibidos
<br>
Sólo podrán ser ingresados en las listas de bienes y servicios ofrecidos, aquellos cuya venta no se encuentre tácita o expresamente prohibida en los Términos y Condiciones Generales y demás políticas de VivaVenta o por la ley vigente. 
El Usuario acepta no anunciar, enviar por email ni de cualquier otra forma poner a disposición contenido que:
sea contrario a la ley,
viole una patente, marca registrada, secreto comercial, derecho de autor u otro derecho de propiedad de cualquier persona, o Contenido respecto del cual el Usuario no tiene derecho a poner a disposición del público conforme a una disposición legal, una relación contractual o fiduciaria.
sea dañino, ilícito, amenazador, abusivo, acosador, difamatorio, contenidos para adultos, prostitución, pornográfico, pedofilia, relacionado con juegos de azar, difamatorio, que invada la privacidad de otros o que dañe a menores de cualquier forma;
acose, degrade, intimide o sea odioso respecto a un individuo o un grupo de individuos por su religión, género, orientación sexual, raza, etnia, edad o discapacidad;
imite a otra persona o entidad, incluyendo un empleado, representante o agente de VivaVenta, o que implique una falsa declaración o que de cualquier manera tergiverse la relación del Usuario con una persona o con una entidad;
incluya información personal o de identificación de otra persona, sin el consentimiento expreso de la persona mencionada;
sea falso, engañoso, de una falsa apariencia, tramposo, engañe respecto de la información o que constituya una forma de "atracción y luego se modifique lo ofrecido";
constituya o contiene "marketing afiliado", un "código de referencia a enlace", "mail basura", "spam", "cadenas de cartas", "esquemas de pirámides" o publicidad comercial no solicitada.
constituya o bien contenga cualquier forma de publicidad o promoción si (1) se publica en áreas o en categorías de los sitios de VivaVenta que no están diseñados para dicha finalidad; o (2) enviado por email a los usuarios que solicitaron no ser contactados con relación a otros servicios, productos o intereses comerciales.
incluya enlaces a servicios comerciales o a sitios en la Web, salvo lo específicamente permitido por VivaVenta;
publicita servicios ilícitos o la venta de artículos cuya venta se encuentra prohibida o restringida por la ley aplicable según el caso;
contiene virus de software u otros códigos, archivos o programas de computación diseñados para interrumpir, destruir o limitar la funcionalidad de un software o de un hardware de computación o de un equipo de telecomunicaciones;
interrumpa el flujo normal del diálogo con una cantidad excesiva de mensajes (ataque masivo) al Servicio, que de alguna forma afecta en forma negativa la capacidad de los Usuarios para utilizar el Servicio;
utilice direcciones de email confusas, o que utilice de cualquier manera la marca VivaVenta o encabezados falsos o que de otra forma manipula los identificadores a fin de ocultar el origen del Contenido transmitido a través del Servicio.
Sumado a ello, el Usuario conviene en:
no contactar a las personas que solicitaron no ser contactadas;
no "acechar" o de otra forma acosar a otros;
no recolectar datos personales sobre otros usuarios con fines comerciales o contrarios a la ley;
no utilizar medios automáticos, con inclusión de arañas, robots, rastreadores, herramientas de ataque a datos, o similares para descargar información del Servicio o del sitio, salvo los motores de búsqueda de Internet (por ejemplo, Google) y archivos públicos no comerciales (por ejemplo, archive.org) que cumplen con nuestro archivo robots.txt;
no anunciar Contenido que no sea local o que por otro motivo carezca de relevancia, publicar un mismo Contenido o Contenido similar en repetidas oportunidades, o de otra forma imponer una carga no razonable o desproporcionada en nuestra infraestructura;
no anunciar un mismo artículo o servicio en más de una categoría de clasificación o foro, en más de un área metropolitana; o
no intentar obtener acceso sin autorización a los sistemas de computación de VivaVenta o bien dedicarse a una actividad que interrumpa, baje la calidad, interfiera con el desempeño o deteriore la funcionalidad del Servicio o del sitio Web de VivaVenta.
no realizar actividades ilegales según la legislación que resulte aplicable en el caso concreto.
<br>
<br>
Indemnidad
<br>
Mediante el presente, el Usuario conviene en que a su cargo corre la indemnización a VivaVenta, su representante, sus funcionarios, afiliados, sucesores, cesionarios, directores, agentes, proveedores de servicios, prestadores y empleados, para mantenerlo indemne de los reclamos o las demandas, con inclusión de los honorarios de los letrados y las costas judiciales razonables, iniciados por terceros a raíz de o bien con relación al Contenido que el Usuario entrega, anuncia o de otra forma pone a disposición de otros a través del Servicio, su uso del Servicio, su violación de las Condiciones, o bien el incumplimiento de las declaraciones y las garantías establecidas en el presente o la violación de los derechos de otros.

</p>
            </div>
        </div>
    </div>
</body>