import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';


import { VariablesGlobales } from './../../../global/variables';


@Injectable({
    providedIn: 'root'
  })

    export class SignService {

        constructor(private http: HttpClient,
                    private variablesGlobales: VariablesGlobales) {}


    readonly URL_API = this.variablesGlobales.URLweb + '/';


        // Loggin
        signin(usuario: string, password: string) {
            const fd = new FormData();
            fd.append('email', usuario);
            fd.append('password', password);
            return this.http.post<any>(this.URL_API + 'signin', fd);
        }

        // Validación de TOKEN
        signValidation(token: string) {
            const fd = new FormData();
            fd.append('token', token);
            return this.http.post<any>(this.URL_API + 'token/tokenValidation', fd);
        }

        // Registración
        signup(nombre: string, apellido: string, email: string, password: string, captcha: string, esWeb: string ) {
          const fd = new FormData();
          fd.append('nombre', nombre);
          fd.append('apellido', apellido);
          fd.append('email', email);
          fd.append('password', password);
          fd.append('captcha', captcha) ;
          fd.append('esWeb', esWeb) ;
          return this.http.post(this.URL_API + 'signup', fd);
        }

        recuperarPassword(usuario: string) {
          const fd = new FormData();
          fd.append('email', usuario);
          return this.http.post<any>(this.URL_API + 'recuperarPassword', fd);
      }


}