import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { InterceptorService } from './interceptors/interceptor.service';

import { LoginPage } from './sign/components/login/login.page'
import { RegistrationPage } from './sign/components/registration/registration.page'
import { IndexComponent } from './index-full/index.component';
import { SitioWebComponent } from './sitio-web/sitio-web.component';
import { GaleriaComponent } from './sitio-web/galeria/galeria.component';
import { ChatComponent } from './sitio-web/chat/chat.component';

import { NgxPaginationModule } from 'ngx-pagination';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { RecaptchaModule } from 'ng-recaptcha';
import { ConocerMas1Component } from './index-full/conocer-mas1/conocer-mas1.component';
import { TerminosComponent } from './index-full/terminos/terminos.component';

import { YouTubePlayerModule } from '@angular/youtube-player';


@NgModule({
  declarations: [
    AppComponent,
    SitioWebComponent,
    IndexComponent,
    GaleriaComponent,
    LoginPage,
    RegistrationPage,
    ChatComponent,
    ConocerMas1Component,
    TerminosComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgxPaginationModule,
    FormsModule, 
    ReactiveFormsModule,
    NgbModule,
    RecaptchaModule,
    YouTubePlayerModule
  ],
  providers: [
    HttpClientModule, 
    HttpClient,
    {provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true},
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
