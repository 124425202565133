import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { VariablesGlobales } from '../../../global/variables';


@Injectable({
  providedIn: 'root'
})

export class ChatService {


  constructor(private http: HttpClient,
              private variablesGlobales: VariablesGlobales) {
  }

  readonly URL_API = this.variablesGlobales.URLweb + '/mensajes/';


  // --------------------Chat

  buscarEmailporSitio(sitioWeb: string){
    const fd = new FormData();
    fd.append('sitioWeb', sitioWeb);
    return this.http.post<any>(this.URL_API + 'chat_sitioWebPorEmail', fd);
  }

  leerMensajes(origen: string, destino: string){
    const fd = new FormData();
    fd.append('origen', origen);
    fd.append('destino', destino);
    return this.http.post<any>(this.URL_API + 'chat_leerMensajes', fd);
  }

  leerMensajesChat(origen: string, destino: string){
    const fd = new FormData();
    fd.append('origen', origen);
    fd.append('destino', destino);
    return this.http.post<any>(this.URL_API + 'chat_sitioWeb_leerMensajes', fd);
  }

  enviarMensaje(origen: string, destino:string, mensaje: string) {
    const fd = new FormData();
    fd.append('origen', origen);
    fd.append('destino', destino);
    fd.append('mensaje', mensaje);
    return this.http.post<any>(this.URL_API + 'chat_enviarMensaje', fd);
  }

  CantidadMensajesSinLeer(origen: string, destino: string){
    const fd = new FormData();
    fd.append('origen', origen);
    fd.append('destino', destino);
    return this.http.post<any>(this.URL_API + 'chat_sitioWebCantidadMensajesSinLeer', fd);
  }

}