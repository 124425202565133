<div *ngIf="isMobile"> 
  <!-- VERSION MOBILE   -->

    <nav class="navbar navbar-expand-lg navbar-light fixed-top navbarnav" style="background:#a1a1a1; border-top: 5px solid rgb(85, 85, 197);  border-bottom: 5px solid linear-gradient(to right,#40E0D0,#8A2BE2,#FF69B4);" >
      
     <div class="container-fluid">

      
      <button class="navbar-toggler" type="button" style="background-color: #636363; border-color:#636363; " 
        data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
        aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      
      <a class="navbar-brand ml-3">
        <!-- <h2 style="color: white"> {{ nombre }} </h2> -->
        <span style="color: white; display:inline-block; margin-left: 20px;" class="shadow3" #nombre ></span>
      </a>
  
      <a class="navbar-brand ml-3">
        <!-- hace que funcione el centrado en el span anterior -->
        <!-- <span></span> -->
        <small> <span *ngIf="variablesGlobales.usuario !== ''" style="color:#636363;"> {{ totalCantidad }}  </span> &nbsp; <i class="fas fa-shopping-cart" style="color:#535353; font-size: 25px;" (click)="toCarritoCompras()"> &nbsp; </i>   </small>
      </a>

      
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        
        <div style="width: 80%; position: absolute; background:#cccccc; border-radius: 5px; margin-left: -20px; margin-top: -60px; padding-left: 15px; padding-right: 15px; border-top: 6px solid rgb(102, 102, 221);">

          <br>
          <br>

              <span style="color: white; display:inline-block; margin-left: 20px;" class="shadow3" #nombre2 ></span>


              <span style="float: right;">
                  <span class="nav-link pr-3" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">          
                        <i class="far fa-window-close" id="botonCerrarMenu" style="color:rgb(95, 89, 139); font-size: 30px;"></i> 
                  </span>
              </span> 
              

          <br>
          <br>          
<hr>        
          <br>
       
          <div class="centrado">
            <div class="row">
              <div class="col-md-3"></div>
      
              <div class="col-md-7">
                <div class="input-group mb-3">
                  <div class="input-group animated fadeIn w-100">
                    <!-- <input type="text" #buscar1 class="form-control ml-5" placeholder="Buscar" (keyup.enter)="search(buscar1.value);">
                    &nbsp; &nbsp;
                    <i class="fas fa-search mt-1 ml-1 btn btn-outline-primary" (click)="search(buscar1.value);buscar1.value=''"  data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"></i> -->
                    <input type="text" #buscar1 class="form-control" placeholder="Buscar" aria-label="" aria-describedby="" (keyup.enter)="search(buscar1.value);buscar1.value='';buscar1CloseMenu.click();">
                    <span class="input-group-text" id="basic-addon2"><i class="fas fa-search" #buscar1CloseMenu (click)="search(buscar1.value);buscar1.value=''" style="color:#9b9a9a" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"></i></span>                    
                  </div>
                </div>
            </div>
          </div>
        </div>

        <br>
        <br>

        <li class="nav-item ml-2 mr-2 mb-2">
          <a class="nav-link btn btn-outline-primary" *ngIf="usuario === ''" (click)="toLogin()">
            &nbsp; <i class="fas fa-sign-in-alt icono1" style="font-size: 18px;"> </i> <span class="shadow3" style="font-size: 18px;"> &nbsp; &nbsp; Ingresar &nbsp; </span>
          </a>
        </li>
        <li class="nav-item dropdown mb-2 mr-1 ml-1">
          <a class="nav-link btn btn-outline-primary dropdown-menu-right" id="navbarDropdownMenuLink"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" *ngIf="usuario !== ''">
            <small (click)="toLogOut()" > &nbsp; <i class="fas fa-user" style="color:rgb(95, 75, 207); font-size: 18px;"></i> &nbsp; {{ usuario }} &nbsp; </small>
          </a>
          <div class="dropdown-menu " aria-labelledby="navbarDropdownMenuLink" style="color:white; background-color: black;">
            <a class="dropdown-item" (click)="toLogOut()" style="color:white; background-color: black;"> <i class="fas fa-sign-out-alt" style="color:rgb(85, 85, 197); font-size: 15px;"></i> Cerrar sesión </a>
          </div>
        </li>

        <br>

          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <a class="nav-link pr3" (click)="toHome()" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                &nbsp; <i class="fas fa-home icono1" style="font-size: 18px;"> </i> <span class="shadow3" style="font-size: 18px;"> &nbsp;  &nbsp; Inicio </span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link pr3" (click)="ngOnInit();toProductos();" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                &nbsp; <i class="fas fa-box-open icono1"> </i> <span class="shadow3" style="font-size: 18px;"> &nbsp; &nbsp; Productos </span>
              </a>                                        
            </li>
            <li class="nav-item">
              <a class="nav-link pr-3" (click)="toContacto()"  data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                &nbsp; <i class="fas fa-address-book icono1" style="font-size: 20px;"> </i> <span class="shadow3" style="font-size: 18px;"> &nbsp; &nbsp; Contacto </span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link pr-3" (click)="toCarritoCompras()"  data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                &nbsp; <i class="fas fa-shopping-cart icono1" > </i> <span class="shadow3" style="font-size: 18px;"> &nbsp; &nbsp;  Carrito de Compras <span *ngIf="variablesGlobales.usuario !== ''"> ( {{ totalCantidad }} ) </span>  </span>
              </a>
            </li>
            <li class="nav-item" >
              <a class="nav-link pr-5" (click)="toMensajes('')">
                &nbsp; <i class="fas fa-comments icono1">  </i> <span class="shadow3" style="font-size: 18px;"> &nbsp; &nbsp; Mensajes <span *ngIf="variablesGlobales.usuario !== ''"> ( {{ CantidadMensajesSinLeer }} ) </span>  </span>
              </a>
            </li>

            
            <br>
            <br>
            &nbsp; 
            <br>

          </ul>  

        </div>

      </div>
      
    </div>
    
  </nav>

    <!-- Header Home -->
    <div id="home" class="home">

      <div style="margin-bottom: 1rem;">
      <br>
      </div>


      <div>

          <div style="margin-top: 45px;">
<!--  -->
              <div class="d-flex align-items-center" style="background-color: #e1e1e1; padding-top: 20px;">

                  <div class="d-inline-block" style="width: 65%;">
              
                    <!-- <div class="input-group mb-3">
                      <div class="input-group-text animated fadeIn w-100">
                        <input type="text" #buscar2 class="form-control ml-5 p-0" placeholder="Buscar" (keyup.enter)="search(buscar2.value);">
                        &nbsp; &nbsp;
                        <i class="fas fa-search mt-1 ml-1 btn btn-outline-primary" (click)="search(buscar2.value);buscar2.value=''"></i>
                      </div>
                    </div> -->
                  <div class="input-group mb-3">
                      &nbsp; <input type="text" #buscar2 class="form-control" placeholder="Buscar" aria-label="" aria-describedby="" (keyup.enter)="search(buscar2.value);" >
                      <span class="input-group-text" id="basic-addon2"><i class="fas fa-search" (click)="search(buscar2.value);buscar2.value=''" style="color:#9b9a9a"></i></span>
                      &nbsp;&nbsp;&nbsp;
                  </div>
              
              
                  </div>
                        <!--  -->
                  <div class="d-inline-block" style="display: flex; align-items: center; justify-content: center;">
              
                    <div>
                        <div class="animated fadeIn" >                          
                              <div (click)="toProductos()">
                                <p style="margin-bottom: -10px;" class="li-border-bottom"> &nbsp;&nbsp; <i class="fas fa-box-open icono1"> </i> 
                                  &nbsp; <span class="shadow1"> PRODUCTOS </span> &nbsp;&nbsp; </p>
                                </div>                    
                      </div>
                    </div>
                
                  </div>
              
              </div>        

          </div>


              <div>
                <!--  -->
                        <ngb-carousel>
                          <ng-template *ngFor="let foto of carousel" ngbSlide>
                            <div class="picsum-img-wrapper containerCarrousel">
                              <img loading="lazy" [src]="URLweb + '/' + foto.fileStore + '/' + foto.db + '/' + foto.FotoURL"
                                style="width: 100%; height: 100%; border-radius: 0em;"
                                class="img-fluid">
                                <div class="overlayCarrousel">
                                  <span>   {{ titulo }}   </span>                                  
                                  <h3 class="mt-1 shadow3"> {{ observacion }} </h3>
                                </div>
                            </div>
                          </ng-template>
                        </ngb-carousel>   
              </div>                
      
      
      </div>                
      
            <div class="col-md-5 my-auto mx-auto">
              <div class="offset-md-2 text-center">
                  <div class="col-md-10 text-center">
                    <!-- <h1 class="mt-5"> {{ titulo }} </h1> -->                    
                    <h3 class="mt-5 shadow3"> {{ aclaracion }} </h3>
                  </div>
                </div>
            </div>

    </div>

    <!-- Productos -->
    <div id="productos">
      
      <div style="background-color: #e1e1e1;">
      <br>
      </div>
<!--  -->
<!--  -->
<div class="d-flex align-items-center" style="background-color: #e1e1e1; padding-top: 10px; display: flex; align-items: center; justify-content: center;">

    <div class="d-inline-block">

      <!-- <div class="input-group mb-3">
        <div class="input-group-text animated fadeIn w-100">
          <input type="text" #buscar2 class="form-control ml-5 p-0" placeholder="Buscar" (keyup.enter)="search(buscar2.value);">
          &nbsp; &nbsp;
          <i class="fas fa-search mt-1 ml-1 btn btn-outline-primary" (click)="search(buscar2.value);buscar2.value=''"></i>
        </div>
      </div> -->
    <div class="input-group mb-3">
        &nbsp; <input type="text" #buscar3 class="form-control" placeholder="Buscar" aria-label="" aria-describedby="basic-addon2" (keyup.enter)="search(buscar3.value);">
        <span class="input-group-text" id="basic-addon2"><i class="fas fa-search" (click)="search(buscar3.value);buscar3.value=''" style="color:#9b9a9a; "></i></span>
        &nbsp;
    </div>


    </div>
          <!--  -->
    <div class="d-inline-block" style="width: 48%;">

      <div>
          <div class="animated fadeIn" >
            
                <div (click)="btnMostrarFiltros()" >
                  <p style="margin-top: 25px;" class="li-border-bottom"> &nbsp; &nbsp; <i class="fas fa-box-open icono1" > </i> 
                  <span class="shadow1"> PRODUCTOS </span> <i class="fas fa-sort-down" style="color:#717171;"></i> &nbsp;&nbsp;&nbsp; &nbsp; </p>
                </div>
      
         </div>
      </div>


    </div>

</div>

<div *ngIf="mostrarFiltros==true" style="background-color: #eeeded; margin-top: -10px;">
  <ul>
    <li class="li-border-bottom" style="margin-top: 0px; margin-bottom: 0px;">
      <a (click)="ngOnInit(); mostrarFiltros=false; toProductos2()">
        <p style="padding-top: 8px; margin-bottom: 4px;" class="shadow2" > TODOS </p>
      </a>
    </li>
  </ul>
  <ul *ngFor="let tp of fotosTipoProducto">
    <li class="li-border-bottom">
      <a (click)="FiltroTipoProducto(tp.valor); toProductos(); mostrarFiltros=false; ">
        <p class="shadow2"> {{ tp.valor }} </p>
      </a>
    </li>
  </ul>
</div>

<!--  -->
<!--  -->


          <!-- panel de fotos -->
          <!-- <div id="fotos" class="col-xl-10"> -->
            <div class="card">

                <div class="row row-cols-2">
                  <div class="col" *ngFor="let foto of fotos | paginate: {itemsPerPage: 24, currentPage: actualPage} ">
                    <div class="card card-photo" style="margin-top: 10px;" >

                      <!-- <div class="card">                 -->
                        <img loading="lazy" [src]="URLweb + '/' + foto.fileStore + '/' + foto.db + '/' + foto.fileNameUUID"
                          class="img-card-top w-100 heaven" 
                          style="border-radius: 3px;"
                          (click)="abrirGaleria(foto.idArticulo)">
                      <!-- </div> -->

                      <div (click)="abrirGaleria(foto.idArticulo)"> 
                        
                        <h5 style="margin-top: 8px;" class="shadow2"> {{ foto.descripcion }} </h5>

                        <p style="color:rgb(63, 116, 216); margin-top: 8px; font-weight: bold;" class="shadow2">$ {{foto.precio}} </p>

                      </div>

                      <img loading="lazy" class="shadow2" src="assets/carrito-compras.jpg"
                      style="width: 10vw; height: 10vw; border-radius:1em;" class="eye1"
                      (click)="agregarCarritoCompras(foto.idArticulo)">
                      <img loading="lazy" class="shadow2" src="assets/chat.jpg"
                      style="width: 10vw; height: 10vw; border-radius:6em;" class="eye2"
                      (click)="toMensajes(foto.descripcion)">


                    </div>
                  </div>
                </div>
            <br>
          <!-- </div> -->

          <div style="margin-top: 25px;">
            <pagination-controls (pageChange)="actualPage = $event" (click)="toProductos()" previousLabel="Previo"
                  nextLabel="Próximo">
            </pagination-controls>
          </div>

        </div>
        
      
    </div>

    <!-- contacto -->
    <div id="contacto">
        <br>
        <br>

        <div class="contorno2" style="font-size: 2px;"> 
          <p>
            &nbsp;
          </p>
        </div>

        <br>
        <br>

        <div class="centrado">
          <img loading="lazy" class="animated zoomIn offset-md-2" src="assets/contactUs.jpg" style="width: 50%; border-radius:3em;">
        </div>

        <div class="row">

          <div class="centrado mb-2">
            <div class="col-md-6">
              <div class="header-content-right animated fadeInUpBig">
              <br>
                <h2 class="mt-4 shadow2"> Contacto </h2>
                <h5 class="mt-4 shadow2"> <i class="fas fa-map-marker-alt"></i> &nbsp; Dirección </h5>
                <h6 class="mt-1 ml-4 shadow2"> {{ direccion }}</h6>
                <h5 class="mt-3 shadow2" > <i class="fas fa-phone"></i> &nbsp; Teléfonos </h5>
                <h6 class="mt-1 ml-4 shadow2"> {{ telefonos }}</h6>
                <h5 class="mt-3 shadow2"> <i class="fas fa-envelope"></i> &nbsp; Email </h5>
                <h6 class="mt-1 ml-4 shadow2"> {{ email }}</h6>
                <h5 class="mt-3 shadow2"> <i class="fas fa-comments"></i> &nbsp; WhatsApp </h5>
                <h6 class="mt-1 ml-4 shadow2"> {{ whatsup }} </h6>
              </div>
            </div>
          </div>


          <div class="col-md-6 mt-5">
            <div class="col-md-6 contorno1">
              <form class="animated" (submit)="EnviarMensaje(Nombre.value, Email.value, Mensaje.value); Nombre.value=''; Email.value=''; Mensaje.value=''">
                <div class="card-body animated fadeIn">
                    <div class="form-group enviarMensaje">
                      <input type="text" placeholder="Nombre" #Nombre class="form-control">
                    </div>
                    <div class="form-group enviarMensaje">
                      <input type="email" placeholder="Email" #Email class="form-control">
                    </div>
                    <div class="form-group enviarMensaje">
                      <textarea rows="6" cols="80" placeholder="Mensaje" #Mensaje class="form-control"></textarea>
                    </div>
                    <button type="submit" class="btn btn-outline-secondary btn-block enviarMensaje" style="background-color: white;">
                      Enviar
                    </button>
                </div>
              </form>              
            </div>

          </div>

        </div>

    </div>

    <br>
    <hr>

    <!-- Carrito de Compras -->
    <div id="carritoCompras">
      <div *ngIf="mostrarCarritoCompras==='si'">
        <br>
        <br>
        <br>
        <div class="text-center">
          <img loading="lazy" class="animated zoomIn" src="assets/carrito-compras.jpg" style="width: 15%; height: 15%; border-radius:3em;">
        </div>
        <br>
        <!-- <div class="text-center bg-secondary"
          style='background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0.33, rgb(110, 122, 122)), color-stop(0.67, rgb(79, 83, 83)));
        background-image: -moz-linear-gradient(center bottom, rgb(110, 122, 122) 33%, rgb(54, 58, 58) 67% ); border-radius: 20px; padding: 2px;'>
          <h1> Carrito de Compras </h1>
        </div>
        <br> -->
        <br>

        <div *ngIf="carritoCompras.length!=0; else carritoComprasVacio">

        
          <div class="col-md-8" style="margin:auto">
            <table class="table table-striped" >
              <thead>
                <th> Producto </th>
                <th> Precio </th>
                <th> Cantidad </th>
                <th> Total </th>
              </thead>

              <tbody *ngFor='let cc of carritoCompras'>
                <td> {{cc.descripcion}} </td>
                <td> {{cc.precio}} </td>
                <td>
                  <a class="btn btn-outline-danger" style="margin-right: 3px;" (click)="sumarItem(cc.idArticulo)">+</a> {{cc.cantidad}} 
                  <a class="btn btn-outline-danger" style="margin-left: 3px;" (click)="restarItem(cc.idArticulo)">-</a> </td>
                <td> {{ cc.cantidad * cc.precio }} </td>
              </tbody>
            </table>
          </div>
        
          <br>
          <h4 class="text-center">
            Total: $ {{ total }}
          </h4>

          <br>
          <div class="row">
            <div class="col-md-3"></div>
            <div class="col-md-5">
            </div>

            <div class="col-md-3">
              <h4 class="btn btn-danger" (click)="hacerPedido()"> >> Hacer pedido! </h4>
            </div>
          </div>
        </div>

        <ng-template #carritoComprasVacio>
          <br>
          <br>
          <h3 class="text-center"> No hay items cargados. Agregue productos al carrito de compras</h3>
        </ng-template>

        <br>
        <br>
        <br>
        <h3 class="btn btn-outline-danger offset-md-1" (click)="verMostrarPedidos()" style="margin-left: 10px;"> Mostrar pedidos hechos </h3>
        <div *ngIf="mostrarPedidos===true; else noMostrarPedidos">
          <br>
          <div *ngIf="carritoComprasPedidos.length!=0; else carritoComprasPedidosVacio ">
            <div class="col-md-8" style="margin: auto">
              <table class="table table-striped">
                <thead>
                  <th> Fecha Pedido </th>
                  <th> Producto </th>
                  <th> Precio </th>
                  <th> Cantidad </th>
                </thead>

                <tbody *ngFor='let ccp of carritoComprasPedidos'>
                  <td> {{ccp.fechaPedido}} </td>
                  <td> {{ccp.descripcion}} </td>
                  <td> {{ccp.precio}} </td>
                  <td> {{ccp.cantidad}} </td>
                </tbody>
              </table>
            </div>

            <br>
            <h4 class="text-center">
              Total: $ {{ totalPedidos }}
            </h4>
  
            <div class="row">
              <div class="col-md-8"></div>
  
              <div class="col-md-3">
                <br>
                <h4 class="btn btn-warning" (click)="cancelarPedido()"> >> Cancelar pedido! </h4>
              </div>
            </div>

          </div>

          <ng-template #carritoComprasPedidosVacio>
            <br>
            <br>
            <h3 class="text-center"> No hay pedidos cargados.</h3>
          </ng-template>

        </div>

        <ng-template #noMostrarPedidos>
        </ng-template>

        <br>
        <br>
        <h3 class="btn btn-outline-danger offset-md-1" (click)="verMostrarRecibidos()" style="margin-left: 10px;"> Mostrar pedidos gestionados </h3>
        <div *ngIf="mostrarRecibidos===true; else noMostrarRecibidos">
          <div class="col-md-8" style="margin: auto">
            <table class="table table-striped">
              <thead>
                <th> Fecha Pedido </th>
                <th> Producto </th>
                <th> Precio </th>
                <th> Cantidad </th>
              </thead>

              <tbody *ngFor='let cce of carritoComprasEntregados | paginate: {itemsPerPage: 10, currentPage: actualPagePedidosGestionados} '>
                <td> {{cce.fechaPedido}} </td>
                <td> {{cce.descripcion}} </td>
                <td> {{cce.precio}} </td>
                <td> {{cce.cantidad}} </td>
              </tbody>
            </table>

            <br>
            <div class="text-center">
              <pagination-controls (pageChange)="actualPagePedidosGestionados = $event" previousLabel="Previo"
                nextLabel="Próximo">
              </pagination-controls>
            </div>            

          </div>
        </div>

        <ng-template #noMostrarRecibidos>
        </ng-template>

        <br>
        <br>

      </div>
    </div>

    
    <br>
    <br>
    <br>
    <!-- pie de página -->
    <hr>
    <div>

      <!-- hardcodeado -->
      <h2 href="https://vivaventa.sytes.net" class="animated flip" style="text-align: center;">
        <a href="https://vivaventa.sytes.net" style="text-decoration: none; color:black;" class="shadow3">VivaVenta <br></a> &nbsp;&nbsp;
      
        <span class="text-center">
          <li><a class="btn btn-warning animated zoomInDown shadow2" href="https://vivaventa.sytes.net/assets/app.apk" download>Descargá la APP para Android</a></li>
          <!-- <a href="https://play.google.com/store/apps/details?id=com.vivaventa.android.vivaventa" class="btn btn-warning animated zoomInDown" > Descarga la app para Android</a> -->
          <!-- <a class="btn btn-warning animated zoomInDown"> Descarga la app para Android</a> -->

        </span>
      </h2>

      <h6 routerLink="/index/terminos" class="animated flip" style="text-align: center;" class="shadow2">
        Términos y Condiciones &nbsp;&nbsp;
      </h6>

    </div>
</div>


<!-- ********************************************************************************************************************************************************* -->
<!-- ********************************************************************************************************************************************************* -->
<!-- ********************************************************************************************************************************************************* -->
<!-- ********************************************************************************************************************************************************* -->
<!-- ********************************************************************************************************************************************************* -->
<!-- ********************************************************************************************************************************************************* -->
<!-- ********************************************************************************************************************************************************* -->


<div *ngIf="!isMobile"> 
  <!-- VERSION DESKTOP -->
  
  <div class="row" style="background:#a1a1a1; border-top: 5px solid rgb(85, 85, 197); padding-top: 25px; position: fixed; width: 100%; top:0; z-index: 1000">    

        <div class="col-md-1 shadow3" style="color: white; margin-left: 20px; text-align: center;" #nombre></div>

        <!-- <div class="col-md-1"></div> -->

        <div class="col-md-6">
          <div class="input-group">
            <div class="input-group-prepend animated fadeIn w-100" style="margin-left: 100px;">
              <input type="text" #buscar class="form-control ml-5" placeholder="Buscar producto..." (keyup.enter)="search(buscar.value); siMostrarProductos(); toProductos()">       
            </div>
          </div>
        </div>

        <div class="col-auto">

          <i class="fas fa-search mt-1 ml-1 btn btn-outline-primary" (click)="search(buscar.value)"></i>       

        </div>

        <div class="text-end" style="position: absolute;">

          <span style="color: white;" (click)="toCarritoCompras()"> 
            {{ totalCantidad }} <i class="fas fa-shopping-cart" style="color: white; font-size: 25px;"></i> 
          </span>

          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;   
          <span class="col-md-3 text-right"> 
            <a class="btn btn-secondary"  *ngIf="usuario === ''" (click)="toLogin()">
              <small> &nbsp; <i class="fas fa-sign-in-alt" style="color:rgb(85, 85, 197)"> &nbsp; </i> &nbsp; Ingresar &nbsp;&nbsp;&nbsp;&nbsp; </small>
            </a>
          </span>
          
          <span *ngIf="usuario !== ''">
            <!-- <div class="dropdown">
              <a class="btn btn-secondary dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Dropdown link
              </a>
            
              <ul class="dropdown-menu">
                <li><a class="dropdown-item" href="#">Action</a></li>
                <li><a class="dropdown-item" href="#">Another action</a></li>
                <li><a class="dropdown-item" href="#">Something else here</a></li>
              </ul>
            </div> -->
            <span class="dropdown">
              <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                    <a>
                      <small> &nbsp; <i class="fas fa-user" style="color:rgb(85, 85, 197)"></i> &nbsp; {{ usuario }} &nbsp; </small>
                    </a>
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li>
                  <a class="dropdown-item"> 
                    <div (click)="toLogOut()" style="color:white; background-color: black;">
                      <i class="fas fa-sign-out-alt" style="color:rgb(85, 85, 197)"> Cerrar sesión </i>
                    </div>
                  </a>
                </li>
              </ul>
            </span>
            <!--  -->
            &nbsp;&nbsp;&nbsp; <i (click)="toLogOut()" class="fas fa-sign-out-alt" style="color:rgb(85, 85, 197)" title="Cerrar sesión"> 
              <!-- Cerrar sesión  -->
            </i>
            <!--  -->
          </span>

        </div>

    <div>
      <br>
    </div>

    <div class="contorno2" style="font-size: 3px;"> 
      &nbsp; 
    </div>


    <div style="background:     linear-gradient(
      to right,
      #969494, /* Turquesa */
      #636363, /* Violeta */
      #969494  /* Rosa */
  ); width: 100%; text-align: center;">

      <ul class="menu ml-3" style="margin-bottom: 5px; margin-top: 5px;">
        <li class="menu">
          <a class="menu pr-3" (click)="noMostrarProductos(); toHome(); ">
            <i class="fas fa-home icono1" style="padding: 5px;"> </i>  <small class="shadow3"> &nbsp; Inicio &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </small>
          </a>
        </li>
        <li class="menu">
          <a class="menu pr-3" (click)="siMostrarProductos(); toProductos(); ngOnInit();">
            <i class="fas fa-box-open icono1" style="padding: 5px;"> </i> <small class="shadow3"> &nbsp; Productos &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </small>
          </a>
        </li>
        <li class="menu">
          <a class="menu pr-3" (click)="toContacto()">
            <i class="fas fa-address-book icono1" style="padding: 6px;"> </i> <small class="shadow3"> &nbsp; Contacto &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </small>
          </a>
        </li>
        <li class="menu">
          <a class="menu pr-3" (click)="toCarritoCompras()">
            <i class="fas fa-shopping-cart icono1" style="padding: 5px;"> </i> <small class="shadow3"> &nbsp; Carrito de Compras <span *ngIf="variablesGlobales.usuario !== ''"> ( {{ totalCantidad }} ) </span>  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </small>
          </a>
        </li>
        <li class="menu">
          <a class="menu pr-3" (click)="toMensajes('')">
            <i class="fas fa-comments icono1" style="padding: 5px;"> </i> <small class="shadow3">  Mensajes <span *ngIf="variablesGlobales.usuario !== ''"> ( {{ CantidadMensajesSinLeer }} ) </span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </small>
          </a>
        </li>
      </ul>

  </div>


  </div>


<hr>
<br>



       <!-- Productos -->

    <div>
      <div>
        <br>
        <div class="animated zoomIn>">

          <div class="row">
            <!-- panel lateral -->
            <div class="col-md-3 animated fadeIn" style="margin-top: 170px; margin-bottom: 30px;">
              <div class="row">
                <div class="col-md-9" >
                  <ul>
                    <ul>
                      <h4 class="shadow3">Categorías</h4>
                      <hr>
                    </ul>
                    <ul>
                      <li class="li-border-bottom" style="margin-top: 0px; margin-bottom: 0px;">
                        <a (click)="ngOnInit(); siMostrarProductos()">
                          <p class="shadow3" style="margin-top: 4px; margin-bottom: 4px;"> Todos </p>
                        </a>
                      </li>
                    </ul>
                    <ul *ngFor="let tp of fotosTipoProducto">
                      <li class="li-border-bottom shadow3">
                        <a (click)="FiltroTipoProducto(tp.valor); siMostrarProductos()">
                          <p style="margin-top: 4px; margin-bottom: 4px;"> {{ tp.valor }} </p>
                        </a>
                      </li>
                    </ul>
                  </ul>
                </div>
              </div>
            </div>
  
            <!-- home -->
              <div id="home" class="container col-md-9" style="margin-top: 0px;" *ngIf="mostrarProductos==='no'">
                <!-- Header Home -->
                <div class="home" style="margin-top: 70px; margin-bottom: 30px;">
                  <div class="container img-fluid centradoHome" >
                    <div class="row">
                        <div class="col-md-5 px-auto my-auto mx-auto">
                          <div class="animated fadeInLeft" style="margin-top: 50px; width: 110%; height: 110%;">
                            <br>
                            <br>
                            <ngb-carousel>
                              <ng-template *ngFor="let foto of carousel" ngbSlide>
                                <div class="picsum-img-wrapper">
                                  <img loading="lazy" [src]="URLweb + '/' + foto.fileStore + '/' + foto.db + '/' + foto.FotoURL"
                                    style="width: 100%; height: 100%; border-radius: 3em;"
                                    class="img-fluid">
                                </div>
                              </ng-template>
                            </ngb-carousel>
                          </div>  
                        </div>
                      
                        <div class="col-md-5 my-auto mx-auto">
                          <div class="offset-md-2 text-center">
                              <div class="col-md-10 text-center">
                                <br>
                                <h2 class="mt-5 shadow3"> {{ titulo }} </h2>
                                <h4 class="mt-5 shadow3"> {{ observacion }} </h4>
                                <h5 class="mt-5 shadow3"> {{ aclaracion }} </h5>
                              </div>
                            </div>
                        </div>

                      </div>
                  </div>
                </div>
                <br>
                <br>
              </div>

            <!-- panel de fotos -->
              <div id="productos" class="col-md-9 mt-0" *ngIf="mostrarProductos==='si'">
                <div class="card mt-4">
                  <div class="card-body animated fadeIn">
                    <br>
                    <br>
                    <br>
                    <div class="row">
                      <div class="col-md-3"
                        *ngFor="let foto of fotos | paginate: {itemsPerPage: 24, currentPage: actualPage} ">
                        <div class="hoverGris" (click)="abrirGaleria(foto.idArticulo)" >
                          <div class="card border-0 card-photo" >

                            <div class="card border-0">                
                              <img loading="lazy" [src]="URLweb + '/' + foto.fileStore + '/' + foto.db + '/' + foto.fileNameUUID"
                                class="img-card-top w-100 heaven " 
                                style="border-radius: 3px; padding: 3px;">
                              <img loading="lazy" src="assets/carrito-compras.jpg"
                                style="width: 18%; height: 16%; border-radius:1em;" class="eye1 imagen hoverRojo"
                                (click)="agregarCarritoCompras(foto.idArticulo)">
                              <img loading="lazy" src="assets/chat.jpg"
                                style="width: 16%; height: 16%; border-radius:6em;" class="eye2 imagen hoverAzul"
                                (click)="toMensajes(foto.descripcion)">
                            </div>
                          
                            <div class="text-center">
                              <h6 style="margin-top: 8px;"> {{ foto.descripcion }} </h6>
                              <h6 style="color:rgb(63, 116, 216); margin-top: 8px; font-weight: bold;">$ {{foto.precio}} </h6>
                            </div>
      
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <br>
                    <div class="text-center">
                      <pagination-controls (pageChange)="actualPage = $event" (click)="toProductos()" previousLabel="Previo"
                        nextLabel="Próximo">
                      </pagination-controls>
                    </div>            
                  <br>

                </div>
              </div>

          </div>
        </div>
      </div>
    </div>

    <!-- contacto -->
    


    <div id="contacto" style="margin-top: -100px;">
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <div class="contorno2" style="font-size: 2px; border-radius: 10px;"> 
          <br>
          <p>
            &nbsp;
          </p>
        </div>
    
        <div class="row" style="margin-top: -8px;">
          <div class="centrado">
            <img loading="lazy" class="animated zoomIn offset-md-1" src="assets/contactUs.jpg" style="width: 30%; border-radius:3em;">
          </div>
        </div>

        
        <div class="row">
          <div class="centrado">
            <!-- <div class="col-md-6">               -->
              <div class="align-items-center animated fadeInUpBig">
              <br>
                <h2 class="mt-4  shadow3"> Contacto </h2>
                <h5 class="mt-4  shadow3"> <i class="fas fa-map-marker-alt"></i> &nbsp; Dirección </h5>
                <h6 class="mt-1 ml-4 shadow3"> {{ direccion }}</h6>
                <h5 class="mt-3 shadow3"> <i class="fas fa-phone"></i> &nbsp; Teléfonos </h5>
                <h6 class="mt-1 ml-4 shadow3"> {{ telefonos }}</h6>
                <h5 class="mt-3 shadow3"> <i class="fas fa-envelope"></i> &nbsp; Email </h5>
                <h6 class="mt-1 ml-4 shadow3"> {{ email }}</h6>
                <h5 class="mt-3 shadow3"> <i class="fas fa-comments"></i> &nbsp; WhatsApp </h5>
                <h6 class="mt-1 ml-4 shadow3"> {{ whatsup }} </h6>
              </div>
            <!-- </div> -->
          </div>        
        </div>

          <br>
          <div class="contorno2" style="font-size: 2px;"> 
            <br>
            <p>
              &nbsp;
            </p>
          </div>
          

         <div class="row">          
          <div class="centrado">
            <div class="col-4 mx-auto">
              <form class="card mt-5" (submit)="EnviarMensaje(Nombre.value, Email.value, Mensaje.value); Nombre.value=''; Email.value=''; Mensaje.value=''">
                <div class="card-body animated fadeIn">
                    <div class="form-group enviarMensaje">
                      <input type="text" placeholder="Nombre" #Nombre class="form-control">
                    </div>
                    <div class="form-group enviarMensaje">
                      <input type="email" placeholder="Email" #Email class="form-control">
                    </div>
                    <div class="form-group enviarMensaje">
                      <textarea rows="6" cols="80" placeholder="Mensaje" #Mensaje class="form-control"></textarea>
                    </div>
                    <button type="submit" class="btn btn-outline-secondary btn-block enviarMensaje">
                      Enviar
                    </button>
                </div>
              </form>
            </div>
          </div>
        </div>

    </div>
    <hr>

    <!-- Carrito de Compras -->
    <div id="carritoCompras">
      <div *ngIf="mostrarCarritoCompras==='si'">
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <div class="text-center">
          <img loading="lazy" class="animated zoomIn" src="assets/carrito-compras.jpg" style="width: 15%; height: 15%; border-radius:3em;">
        </div>
        <br>
        <br>

        <div *ngIf="carritoCompras.length!=0; else carritoComprasVacio">

        
          <div class="col-md-8" style="margin:auto">
            <table class="table table-striped" >
              <thead>
                <th> Producto </th>
                <th> Precio </th>
                <th> Cantidad </th>
                <th> Total </th>
              </thead>

              <tbody *ngFor='let cc of carritoCompras'>
                <td> {{cc.descripcion}} </td>
                <td> {{cc.precio}} </td>
                <td> <a class="btn btn-outline-danger" style="margin-right: 3px;" (click)="sumarItem(cc.idArticulo)">+</a>  {{cc.cantidad}}
                  <a class="btn btn-outline-danger" style="margin-left: 3px;" (click)="restarItem(cc.idArticulo)">-</a> </td>
                <td> {{ cc.cantidad * cc.precio }} </td>
              </tbody>
            </table>
          </div>
        
          <br>
          <h4 class="text-center">
            Total: $ {{ total }}
          </h4>

          <br>
          <div class="row">
            <div class="col-md-8"></div>

            <div class="col-md-3">
              <h4 class="btn btn-danger" (click)="hacerPedido()"> >> Hacer pedido! </h4>
            </div>
          </div>
        </div>

        <ng-template #carritoComprasVacio>
          <br>
          <br>
          <h3 class="text-center"> No hay items cargados. Agregue productos al carrito de compras</h3>
        </ng-template>

        <br>
        <br>
        <br>
        <h3 class="btn btn-outline-danger offset-md-1" (click)="verMostrarPedidos()" style="margin-left: 10px;"> Mostrar pedidos hechos </h3>
        <div *ngIf="mostrarPedidos===true; else noMostrarPedidos">
          <br>
          <div *ngIf="carritoComprasPedidos.length!=0; else carritoComprasPedidosVacio ">
            <div class="col-md-8" style="margin: auto">
              <table class="table table-striped">
                <thead>
                  <th> Fecha Pedido </th>
                  <th> Producto </th>
                  <th> Precio </th>
                  <th> Cantidad </th>
                </thead>

                <tbody *ngFor='let ccp of carritoComprasPedidos'>
                  <td> {{ccp.fechaPedido}} </td>
                  <td> {{ccp.descripcion}} </td>
                  <td> {{ccp.precio}} </td>
                  <td> {{ccp.cantidad}} </td>
                </tbody>
              </table>
            </div>

            <br>
            <h4 class="text-center">
              Total: $ {{ totalPedidos }}
            </h4>
  
            <div class="row">
              <div class="col-md-8"></div>
  
              <div class="col-md-3">
                <h4 class="btn btn-warning" (click)="cancelarPedido()"> >> Cancelar pedido! </h4>
              </div>
            </div>

          </div>

          
          <ng-template #carritoComprasPedidosVacio>
            <br>
            <br>
            <h3 class="text-center"> No hay pedidos cargados.</h3>
          </ng-template>

        </div>

        <ng-template #noMostrarPedidos>
        </ng-template>

        <br>
        <br>
        <h3 class="btn btn-outline-danger offset-md-1" (click)="verMostrarRecibidos()" style="margin-left: 10px;"> Mostrar pedidos gestionados </h3>
        <div *ngIf="mostrarRecibidos===true; else noMostrarRecibidos">
          <div class="col-md-8" style="margin: auto">
            <table class="table table-striped">
              <thead>
                <th> Fecha Pedido </th>
                <th> Producto </th>
                <th> Precio </th>
                <th> Cantidad </th>
              </thead>

              <tbody *ngFor='let cce of carritoComprasEntregados | paginate: {itemsPerPage: 10, currentPage: actualPagePedidosGestionados} '>
                <td> {{cce.fechaPedido}} </td>
                <td> {{cce.descripcion}} </td>
                <td> {{cce.precio}} </td>
                <td> {{cce.cantidad}} </td>
              </tbody>
            </table>

            <br>
            <div class="text-center">
              <pagination-controls (pageChange)="actualPagePedidosGestionados = $event" previousLabel="Previo"
                nextLabel="Próximo">
              </pagination-controls>
            </div>            

          </div>
        </div>

        <ng-template #noMostrarRecibidos>
        </ng-template>

        <br>
        <br>

      </div>
    </div>

    <br>
    <br>
    <br>
    <br>
    <!-- pie de página -->
    <hr>
    <div>

      <!-- Hardcodeado -->
      <h3 href="https://vivaventa.sytes.net" class="animated flip" style="text-align: center;">
        <a href="https://vivaventa.sytes.net" style="text-decoration: none; color:black" class="shadow3">VivaVenta </a> &nbsp;&nbsp;
      
        <span class="text-center">
          <a class="btn btn-warning animated zoomInDown" href="https://vivaventa.sytes.net/assets/app.apk" download >Descargá la APP para Android</a>
          <!-- <a href="https://play.google.com/store/apps/details?id=com.vivaventa.android.vivaventa" class="btn btn-warning animated zoomInDown" > Descarga la app para Android</a> -->
          <!-- <a class="btn btn-warning animated zoomInDown" style="margin-left: 10px;" > Descarga la app para Android</a> -->
        </span>
      </h3>


      <h6 routerLink="/index/terminos" class="animated flip" style="text-align: center;">
        Términos y Condiciones &nbsp;&nbsp;
      </h6>

      <div style="text-align: center">
          <span> COPYRIGHT </span>
          <h6 style="size: 10px;" #nombre2> </h6> 
      </div>
    </div>
</div>
