import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-conocer-mas2',
  templateUrl: './conocer-mas2.component.html',
  styleUrls: ['./conocer-mas2.component.css']
})
export class ConocerMas2Component implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
    setTimeout(function () { 
      document.getElementById("wrapper").scrollIntoView({ behavior: "smooth", block: 'start', inline: 'center' });
    }, 100)
  }

  goToInicio(){
    this.router.navigate(['/'])
  }


}
