<body>

    <div class="is-preload">
        <!-- Wrapper -->
        <div id="wrapper">

            <!-- Header -->
                <!-- <header id="header" class="alt"> -->
        <div style="text-align: center; padding-top: 40px; padding-bottom:20px">
                <div class="animated rotateIn"><img (click)="goToInicio();" style="width: 5em; height: 5em; border-radius: 3em;" src="assets/css/images/VivaVenta.PNG" alt="" />
        </div>
        <!-- <h1 >VivaVenta</h1> -->
        <br>
        <br>
        <p>
            - ¿Cómo es el servicio?
            <br>
            Este servicio es por suscripción mensual o anual.
        </p>    
        <p>
            - ¿En que sistemas puedo utilizar la aplicación?
            <br>
            El sistema además de funcionar como aplicación para móviles Android, funciona en cualquier navegador, tanto para PC, como tablets, y celulares Android e Iphone.
        </p>
        <p>
            - ¿Cómo es el funcionamiento del lector de código de barras?
            <br>
            El lector de códigos de barras funciona con la aplicación VivaVenta solo para móviles Android.
        </p>    
        <p>
            - ¿Necesito tener conexión a internet?
            <br>
            Sí, el sistema se encuentra en la nube, por lo que se necesita obligatoriamente tener conexión a internet, la misma puede ser por WIFI o Datos del celular.
        </p>    
        <p>
            - ¿Cuenta con sistema de facturación?
            <br>
            No, al momento, este sistema no cuenta con la opción de emitir comprobantes válidos por la AFIP.
        </p>    
        <p>
            - ¿Qué beneficios tengo al tener mi información en la nube?
            <br>
            Tiene el beneficio de tener total accesibilidad desde cualquier lugar con diferentes tipos de dipositivos, ideal para tener control de toda la gestión comercial en todo momento.
        </p> 
        <p>
            - ¿Como se resguarda mi información?
            <br>
            Toda la información se encuentra en la nube en servidores de alta disponibilidad con sistemas de redundancia y medios de resguardo seguros. Solo accesible por el usuario de la cuenta o a quién delege el acceso según su propia administración.
        </p>    
        <p>
            - ¿Puedo exportar toda mi información?
            <br>
            Si, desde los "Reportes" se puede exportar en cualquier momento toda la información cargada en el sistema a archivos de texto.
        </p>    
        <p>
            - ¿Cuales son los medios de pago de la suscripción?
            <br>
            El medio de pago de pago de las suscripciones tanto mensuales como anuales es por MercadoPago. El sistema comenzará a informar al usuario 15 días antes de que finalice la suscripción.
            *El procesamiento de los pagos puede demorar 48hs.
        </p>    

        
      </div>


            <!-- Footer -->
                <footer id="footer">
                    <section>
                        <h2>VivaVenta</h2>
                        <p>Ofrecemos soluciones en sistemas informáticos brindando así potenciar su comercio, exponenciando ventas y agilizando la administración.</p>
                        <ul class="actions">
                            <!-- <li><a href="generic.html" class="button">Learn More</a></li> -->
                        </ul>
                    </section>
                    <section>
                        <h2>Contacto</h2>
                        <dl class="alt">
                            <dt>WhatsApp</dt>
                            <dd>(054) 011 - 2288 - 20EB</dd>
                            <dt>Email</dt>
                            <dd><a href="#">contacto&#64;vivaventa.sytes.net</a></dd>
                        </dl>
                        <!-- <ul class="icons">
                            <li><a href="#" class="icon brands fa-twitter alt"><span class="label">Twitter</span></a></li>
                            <li><a href="#" class="icon brands fa-facebook-f alt"><span class="label">Facebook</span></a></li>
                            <li><a href="#" class="icon brands fa-instagram alt"><span class="label">Instagram</span></a></li>
                            <li><a href="#" class="icon brands fa-github alt"><span class="label">GitHub</span></a></li>
                            <li><a href="#" class="icon brands fa-dribbble alt"><span class="label">Dribbble</span></a></li>
                        </ul> -->
                    </section>
                    
                </footer>

        </div>

    </div>


<footer>
    <div>
        <h6></h6>
    </div>
    <div class="animated flip text-center">
        VivaVenta
    </div>
    <h6 routerLink="index/terminos" style="text-align: center;">
        Términos y Condiciones &nbsp;&nbsp;
    </h6>
</footer>

</body>