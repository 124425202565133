<body>

    <div class="is-preload">
        <!-- Wrapper -->
        <div id="wrapper">

            <!-- Header -->
                <!-- <header id="header" class="alt"> -->
        <div style="text-align: center; padding-top: 40px; padding-bottom:20px">
                <div class="animated rotateIn"><img (click)="goToInicio();" style="width: 5em; height: 5em; border-radius: 3em;" src="assets/css/images/VivaVenta.PNG" alt="" />
        </div>
        <!-- <h1 >VivaVenta</h1> -->
        <br>
        <br>
        <p>
            Primero registrate en en el sistema con una cuenta de mail válida haciendo click en el botón "Crear cuenta" desde "Ingresar" desde la página principal.
        </p>    
        <p>
            En cada pantalla de la aplicación encontrará en el borde superior derecho un ícono de ayuda "(?)" donde se mostrará una ayuda de lo que puede realizar en dicha sección.
        </p>    
        <p>
            Ingrese a "Opciones", luego a "Página Web" y configure los datos de su página web, como la dirección de su página web, nombre que se mostrará, actividad de su comercio, datos de contacto. Cargue también imágenes de su comercio o fotos de sus productos, dichas imágenes se mostrarán como presentación de su página web.
        </p>
        <p>
            Ingrese a "Productos" para cargar los árticulos o servicios de su comercio, es conveniente cargar fotos por cada producto, dichas imágenes se mostrarán en su "Página Web" aumentando la exposición de lo que quiere ofrecer.
        </p>    
        <p>
            Gestione las ventas y compras desde los botones de "Vender" y "Comprar", cada movimiento será actualizado en el stock del ítem gestionado y tendrá visualización de la actividad desde los "Reportes".
        </p>    
        <p>
            Gestione los pedidos que los clientes realicen a través de su "Página Web" a través del botón "Pedidos Clientes". Desde allí puede gestionar la venta de un pedido, chatear con sus clientes o cancelar pedidos.
        </p> 
        <p>
            Desde el botón "Reportes" dispone de todos los reportes necesarios para controlar la actividad de su comercio, así como compras, ventas, pedidos, productos, stock de productos, productos a vencer, realizar auditorías, controlar su stock general, cantidad de artículos, etc. Cada reporte se puede exportar a un archivo de planilla de cálculos para un posterior análisis o mismo backup de datos.
        </p>    
        <p>
            Cuenta también con módulos de gastos e ingresos, para registrar sus movimientos, como gastos de alquiler, factura de electricidad; para luego consultar a través de los reportes el rendimiento de la gestión comercial.
        </p>    
        
      </div>


            <!-- Footer -->
                <footer id="footer">
                    <section>
                        <h2>VivaVenta</h2>
                        <p>Ofrecemos soluciones en sistemas informáticos brindando así potenciar su comercio, exponenciando ventas y agilizando la administración.</p>
                        <ul class="actions">
                            <!-- <li><a href="generic.html" class="button">Learn More</a></li> -->
                        </ul>
                    </section>
                    <section>
                        <h2>Contacto</h2>
                        <dl class="alt">
                            <dt>WhatsApp</dt>
                            <dd>(054) 011 - 2288 - 20EB</dd>
                            <dt>Email</dt>
                            <dd><a href="#">contacto&#64;vivaventa.sytes.net</a></dd>
                        </dl>
                        <!-- <ul class="icons">
                            <li><a href="#" class="icon brands fa-twitter alt"><span class="label">Twitter</span></a></li>
                            <li><a href="#" class="icon brands fa-facebook-f alt"><span class="label">Facebook</span></a></li>
                            <li><a href="#" class="icon brands fa-instagram alt"><span class="label">Instagram</span></a></li>
                            <li><a href="#" class="icon brands fa-github alt"><span class="label">GitHub</span></a></li>
                            <li><a href="#" class="icon brands fa-dribbble alt"><span class="label">Dribbble</span></a></li>
                        </ul> -->
                    </section>
                    
                </footer>

        </div>

    </div>


<footer>
    <div>
        <h6></h6>
    </div>
    <div class="animated flip text-center">
        VivaVenta
    </div>
    <h6 routerLink="index/terminos" style="text-align: center;">
        Términos y Condiciones &nbsp;&nbsp;
    </h6>
</footer>

</body>