import { Component, OnInit } from '@angular/core';

import { SignService } from '../../services/sign.services';
import { Router, ActivatedRoute } from '@angular/router';

import { NgForm, FormControl, UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControl } from '@angular/forms';

import { passwordValidator } from './password.validator';

import Swal from 'sweetalert2';


@Component({
  selector: 'app-registration',
  templateUrl: './registration.page.html',
  styleUrls: ['./registration.page.scss'],
})
export class RegistrationPage implements OnInit {

  formulario: UntypedFormGroup;


  constructor(private signService: SignService,
              private router: Router,
              private fb: UntypedFormBuilder,
              private activatedRoute: ActivatedRoute) {
              this.formulario = this.fb.group({
                nombre: [null, Validators.compose([Validators.required, Validators.pattern(/^[ áéíóúäëïöüñÑ@a-zA-Z0-9_-]*$/), 
                  Validators.minLength(1), Validators.maxLength(50)])],
                apellido: [null, Validators.compose([Validators.required, Validators.pattern(/^[ áéíóúäëïöüñÑ@a-zA-Z0-9_-]*$/), 
                  Validators.minLength(1), Validators.maxLength(50)])],
                email: [null, Validators.compose([Validators.required, Validators.email])],
                password: [null, Validators.compose([Validators.required, Validators.pattern(/^[ áéíóúäëïöüñÑ@a-zA-Z0-9_-]*$/), 
                  Validators.minLength(8), Validators.maxLength(20)])],
                password2: [null, Validators.compose([Validators.required, Validators.pattern(/^[ áéíóúäëïöüñÑ@a-zA-Z0-9_-]*$/), 
                  Validators.minLength(8), Validators.maxLength(20)])],
              }, { validator: passwordValidator }
              );
            }


  sitioWeb;
  captchaResponse: string;


  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.sitioWeb = params.sitioWeb;
    })
    setTimeout(function () { 
      document.getElementById("registracion").scrollIntoView({ behavior: "smooth", block: 'start', inline: 'start' });
    }, 1000)
  }

  captchaResolved(captchaResolved: string){
    this.captchaResponse = captchaResolved
    console.log(this.captchaResponse)
  }

  signup(nombre: string, apellido: string, email: string, password: string) {

    Swal.fire({
      title: '¿Confirmar Registración?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'No',
      confirmButtonText: 'Si',
      heightAuto: false
    }).then((result) => {
      if (result.value) {
         // modificar el último parámetro a "si" para captcha web, y "no" para captcha de app android
        this.signService.signup(nombre, apellido, email, password, this.captchaResponse, 'si').subscribe(res => {
          if (res[0].RESULTADO === 'noCaptcha') { 
            Swal.fire({
              icon: 'error',
              title: 'Reintente "Captcha"',
              text: 'Intente nuevamente la validación',
              showConfirmButton: false,
              timer: 3500,
              heightAuto: false
              });
          }else  if (res[0].RESULTADO === 'emailExistente') {
            Swal.fire({
              icon: 'error',
              title: 'Email existente!',
              text: 'Elija otra cuenta de Email',
              showConfirmButton: false,
              timer: 3500,
              heightAuto: false
            });
          } else {
            this.router.navigate(['/login/' + this.sitioWeb, 1])
            Swal.fire({
              icon: 'success',
              title: 'Registración generada',
              showConfirmButton: false,
              timer: 2500,
              heightAuto: false
            });
          }
        });
      }
    });
  }

  toLogin(){
    this.router.navigate(['/login/' + this.sitioWeb, 1])
  }
}
