import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';

import { ChatService } from '../services/chat.service';



@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit {

  constructor(private router: Router,
              private activateRoute: ActivatedRoute,
              private chatService: ChatService
              ) { }

  @ViewChild('mensaje') private elementRef: ElementRef;
  myEmail;
  destinatario;
  mensajes;
  nuevoMsj;
  sitioWeb


  ngOnInit() {
    this.activateRoute.params.subscribe(result => {
      this.destinatario = result.usuario
      this.sitioWeb = result.sitioWeb
      this.nuevoMsj = result.mensaje
      this.chatService.buscarEmailporSitio(result.sitioWeb).subscribe(result => {
        this.myEmail = result[0].email;
        this.chatService.leerMensajes(result[0].email, this.destinatario).subscribe(result => {
          this.mensajes = result;
          setTimeout(() => {
            document.getElementById("marca").scrollIntoView({ behavior: "smooth" });
            this.elementRef.nativeElement.focus();
          },1000)
        })
      })
    })
  }

  enviarMensaje(mensaje: string){
    this.chatService.enviarMensaje(this.destinatario, this.myEmail, mensaje).subscribe(result => {
      this.chatService.leerMensajesChat(this.myEmail, this.destinatario).subscribe(result => {
        this.mensajes = result;
        this.nuevoMsj=''
        setTimeout(() => { 
          document.getElementById("marca").scrollIntoView();
          this.elementRef.nativeElement.focus();
        },1000)  
      })
    })
  }

  volver(){
    this.router.navigate(['/'+ this.sitioWeb, 0] )
  }

}
