import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {


  constructor(private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const tokenEGG = localStorage.getItem('token');

    console.log ('Pasó por el interceptor, TOKEN: ');
    console.log(tokenEGG)

    const reqClone = req.clone({
      setHeaders: { 
        Authorization: tokenEGG 
        }
    });

    
    if (tokenEGG === null) {
      return next.handle ( req ).pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.status == 403) {
            // console.log('entró en el interceptor de 403')
            this.router.navigate(['/login']);
          } else {
            return throwError(err);
          }
        })
      );     
    }
    else {
      return next.handle ( reqClone ).pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.status == 403) {
            // console.log('entró en el interceptor de 403')
            this.router.navigate(['/login']);
          } else {
            return throwError(err);
          }
        })
      ); 
    }
  }


}
